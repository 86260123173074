.m-module {
  flex: 1;
  position: relative;
  padding-left: 60px;

  img {
    max-width: 100%;
  }

  ul li,
  ol li {
    color: $tp-color;
  }

  @include big {
    padding-left: 0;
  }

  &.customer {
  }

  // category-module
  .category-module {
    position: relative;

    // headline
    h5 {
      padding-left: 40px;
      margin-bottom: 65px;

      @include big {
        padding-left: 0;
        text-align: center;
      }
    }

    // wrapper
    .wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      box-sizing: border-box;
      margin: 0;

      @include big {
        margin: 0 auto;
        justify-content: center;
      }
    }

    // col
    .col {
      width: 410px;
      padding: 0 25px;
      margin-bottom: 75px;

      @include big {
        width: 50%;
        max-width: 370px;
        padding: 0 20px;

        &:last-child {
          margin-bottom: 65px;
        }
      }

      @include small {
        width: 100%;
      }
    }
  }

  // module
  .module {
    position: relative;
    margin-bottom: 80px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    @include big {
      // padding: 0 20px;
      margin-bottom: 65px;
    }

    @include medium {
      margin-bottom: 60px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 30px;

      @include big {
        margin-bottom: 20px;
      }
    }

    &.text {
      .shape {
        display: block;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;

        @include medium {
          width: 38px;
          height: 38px;
        }
      }

      a {
        // word-break: break-all;
        // update 2022-09-20
        word-break: normal;
        color: $happy-red;
        &:hover {
          text-decoration: underline;
        }

        &.u-button.default {
          color: $white-color;
          &:hover {
            text-decoration: none;
          }
        }

        &.u-link.demo {
          &:hover {
            color: $white-color;
            text-decoration: none;
          }
        }
      }

      p {
        margin-bottom: 20px;
      }

      ol {
        list-style: none;
        counter-reset: list-counter;

        li {
          position: relative;
          color: $tp-color;
          font-size: 18px;
          line-height: 27px;
          margin-top: 20px;
          font-weight: 300;
          padding-left: 20px;
          counter-increment: list-counter;

          &:before {
            content: counters(list-counter, ".") ".";
            color: $tp-color;
            font-size: inherit;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

      ul {
        padding-left: 20px;
        list-style-position: outside;
        list-style-type: disc;

        li {
          color: $tp-color;
          font-size: 18px;
          line-height: 27px;
          font-weight: 300;
          margin-top: 20px;
        }
      }

      hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid $ink-spot-25;
        margin: 20px 0;
        padding: 0;
      }

      // content
      > .content {
        position: relative;

        > *:first-child {
          margin-top: 0 !important;
        }

        > *:last-child {
          margin-bottom: 0 !important;
        }

        > p:first-child {
          margin-top: -12px !important;

          img {
            padding-top: 12px !important;
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 30px;

          @include big {
            margin-bottom: 20px;
          }
        }

        img {
          display: block;
          margin: 0;
          padding: 0;
          max-width: 100%;
          height: auto;
          object-fit: cover;
          // margin-bottom: 30px;

          &.aligncenter {
            margin: 0 auto;
            // margin-bottom: 30px;
          }

          &.alignleft {
            float: left;
            margin-right: 60px;
            margin-bottom: 20px;

            @include medium {
              margin-right: 0;
              float: none;
            }
          }
          &.alignright {
            float: right;
            margin-left: 60px;
            margin-bottom: 20px;

            @include medium {
              margin-left: 0;
              float: none;
            }
          }
        }
      }
    }

    // module-gallery
    &.gallery {
      position: relative;
      margin-bottom: 85px;
      min-width: 100%;
      width: 0;

      // img
      img {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      // video-embed
      .video-embed {
        position: relative;
        padding-top: 58.684%;
        max-width: 100%;
        height: 0;
        overflow: hidden;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: none;
        }
      }

      // slider navigation
      .slick-dots {
        margin-left: -20px;
        margin-right: -20px;
        bottom: -45px;

        li {
          margin: 0 20px;
          &.slick-active {
            .slider-dot {
              background: $grass-green;
            }
          }
          .slider-dot {
            display: inline-block;
            width: 42px;
            height: 7px;
            background: $tmp-6;
            transition: all 200ms ease-in-out;

            &:hover {
              background: $grass-green;
            }
          }
        }
      }
    }

    // module-features
    &.features {
      // wrapper
      .wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -40px;
        box-sizing: border-box;

        @include big {
          justify-content: space-between;
          margin: 0;
        }

        @include small {
          justify-content: flex-start;
        }
      }

      // clip
      .clip {
        display: block;
        margin: 40px auto;
        padding: 0;
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    // module-boxes
    &.boxes {
      // wrapper
      .wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: -20px;
      }
    }

    // module-boxes
    &.statistics {
      h4 {
        margin-bottom: 65px;
      }

      // wrapper
      .wrapper {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: -20px;
      }
    }

    &.quote {
      // content-quote
      .content-quote {
        text-align: center;

        .like-h4 {
          position: relative;
          font-size: 30px;
          line-height: 36px;

          @include big {
            font-size: 24px;
            line-height: 28px;
          }

          span {
            color: $grass-green;

            &:first-child {
              margin-left: -25px;

              @include big {
                margin-left: -14px;
              }
            }
          }
        }

        // overline
        .overline-2 {
          display: block;
          margin-top: 20px;
        }

        // button
        .u-button {
          margin-top: 20px;
        }
      }
    }

    // specification-legend
    &.spec-legend {
      // content
      > .content {
        position: relative;

        > *:first-child {
          margin-top: 0 !important;
        }

        > *:last-child {
          margin-bottom: 0 !important;
        }

        // legend-list
        .legend-list {
          margin-top: 20px;
          position: relative;

          .box {
            display: flex;

            span {
              display: block;
              font-size: 16px;
              line-height: 24px;
              font-weight: 700;
              margin-top: 20px;
              padding-right: 20px;
            }

            p {
              display: block;
              font-size: 16px;
              line-height: 24px;
              font-weight: 300;
              margin-top: 20px;
            }
          }
        }

        // legend-iocn
        .legend-icon {
          position: relative;
          margin: 0 -40px;
          margin-top: 40px;
          display: flex;

          @include small {
            margin: 0;
            margin-top: 40px;
            flex-direction: column;
          }

          span {
            position: relative;
            display: block;
            padding-left: 52px;
            font-size: 16px;
            line-height: 24px;
            margin: 0 40px;

            @include small {
              margin: 0;
              margin-top: 40px;

              &:first-child {
                margin-top: 0;
              }
            }

            &.enabled {
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-image: url("../img/checkmark.svg");
                width: 37px;
                height: 37px;
              }
            }
            &.disabled {
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-image: url("../img/xmark.svg");
                width: 37px;
                height: 37px;
              }
            }
          }
        }
      }
    }

    // specification-table
    &.spec-table {
      // content
      > .wrapper {
        position: relative;

        > *:first-child {
          margin-top: 0 !important;
        }

        > *:last-child {
          margin-bottom: 0 !important;
        }

        // table
        table.specification {
          display: block;
          width: 100%;
          margin: 0 auto;
          padding: 0;
          border-spacing: 10px;
          border-collapse: collapse;
          font-family: $real-text;

          .hidden-mobile {
            @include small {
              display: none !important;
            }
          }

          thead {
            vertical-align: middle;
            background-color: $grass-green-25;

            tr,
            th {
              border-collapse: collapse;
              font-weight: 600;
              padding: 20px;
              text-align: left;
              font-size: 18px;
              line-height: 22px;
              color: $black-color;
            }

            th:first-child {
              min-width: 250px;

              @include small {
                min-width: 100%;
              }
            }

            th:last-child {
              min-width: 110px;
              padding-left: 0;

              @include small {
                min-width: 100%;
              }
            }
          }
          tbody {
            vertical-align: top;

            tr {
              td {
                position: relative;
                border-collapse: collapse;
                padding: 20px 20px;
                text-align: left;
                font-size: 16px;
                line-height: 24px;
                color: $tp-color;
                font-weight: 300;
                word-wrap: break-word;
                overflow-wrap: break-word;

                &:first-child {
                  @include small {
                    padding-right: 40px;
                  }
                }

                &.enabled {
                  color: $grass-green;
                  font-weight: 700;
                  padding-left: 52px !important;

                  &:after {
                    content: "";
                    position: absolute;
                    top: 33px;
                    left: 0;
                    background-image: url("../img/checkmark.svg");
                    width: 37px;
                    height: 37px;
                  }
                }

                &.disabled {
                  color: $disable-color;
                  font-weight: 700;
                  padding-left: 52px !important;

                  &:after {
                    content: "";
                    position: absolute;
                    top: 33px;
                    left: 0;
                    background-image: url("../img/xmark.svg");
                    width: 37px;
                    height: 37px;
                  }
                }
              }
            }

            tr.content {
              border-bottom: 1px solid #d8d8d8;

              td {
                padding: 40px 20px;

                p:not(:last-child) {
                  margin-bottom: 20px;
                }
              }
            }

            tr.heading {
              background-color: $grass-green-8;

              p:not(:last-child) {
                margin-bottom: 20px;
              }
            }
          }
        }

        // button
        .buttons {
          margin: 0 auto;
          margin-top: 40px;
          text-align: center;
        }
      }
    }
  }
}
