.m-platform {
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // text
    .overline-1 {
        margin-bottom: 24px;
    }

    // headline
    h3 {
        margin-bottom: 26px;
    }

    // platform
    .platform-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        margin-top: 73px;
        min-height: 490px;

        @include big {
            min-height: 100%;
        }

        // box
        .box {
            z-index: 2;
            position: absolute;
            display: flex;
            width: 360px;
            box-sizing: border-box;
            transition: all 200ms ease-in-out;

            @include big {
                position: relative;
                width: 100%;
                flex-wrap: wrap;
                cursor: default;
                margin-bottom: 20px;
            }

            &.is-active {

                // content
                .content {
                    h6, .tlb {
                        color: $black-color;
                    }
                }

                // content icon
                .content-icon {

                    // icon
                    .icon {

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            background: $grass-green;
                            box-shadow: 0 0 0 13px $tmp-8;
                            width: 53px;
                            height: 53px;
                            border-radius: 50%;
                            opacity: 0;
                            animation: show 400ms ease-in-out forwards;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            z-index: 10;
                            display: block;
                            animation: show 400ms ease-in-out
                        }


                        @keyframes show {
                            0%{opacity: 0;}
                            100%{opacity: 1;}
                        }

                    }
                }
            }

            &.right {
                text-align: right;
                justify-content: space-between;

                @include big {
                    text-align: left;
                    justify-content: flex-start;
                }

                // content-icon
                .content-icon {
                    order: 1;
                    justify-content: flex-end;

                    @include big {
                        order: 0;
                        justify-content: flex-start;
                    }
                }

            }

            &:nth-child(1){
                top: 370px;
                left: 0;

                @include big {
                    top: 0;
                }
            }

            &:nth-child(2){
                top: 200px;
                left: 0;

                @include big {
                    top: 0;
                }
            }

            &:nth-child(3){
                top: 30px;
                left: 0;

                @include big {
                    top: 0;
                }
            }

            &:nth-child(4){
                top: 30px;
                right: 0;

                @include big {
                    top: 0;
                }
            }

            &:nth-child(5){
                top: 200px;
                right: 0;

                @include big {
                    top: 0;
                }
            }

            // content-icon
            .content-icon {
                margin-top: 3px;
                width: 60px;
                display: flex;
                justify-content: flex-start;

                @include big {
                    width: 80px;
                    margin-top: 30px;
                    margin-left: 30px;
                }

                // icon
                .icon {
                    position: relative;
                    display: block;
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background: $grass-green;
                    box-shadow: 0 0 0 7px $tmp-8;

                    img {
                        display: none;
                    }

                    @include big {

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            background: $grass-green;
                            box-shadow: 0 0 0 13px $tmp-8;
                            width: 53px;
                            height: 53px;
                            border-radius: 50%;
                            opacity: 1;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            z-index: 10;
                            display: block;
                        }

                    }
                }
            }


            // content
            .content {
                max-width: 300px;
                color: $tmp-7;

                @include big {
                    max-width: 100%;
                    flex: 1;
                }

                // headline
                h6 {
                    margin-bottom: 10px;
                    color: $tmp-7;

                    @include big {
                        font-size: 20px;
                        color: $black-color;
                    }
                }

                // text
                .tlb {
                    font-size: 16px;
                }

                // link
                .u-link {
                    display: none;
                    margin: 20px 0;
                    margin-left: -19px;
                    @include big {
                        display: inline-block;
                    }
                }

            }

            &:hover .content h6,
            &:hover .content .tlb {
                color: $black-color;
            }

        }

        // platform cake
        .platform-cake {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 362px;
            height: 490px;

            @include big {
                display: none;
            }

            // wrapper
            .wrapper {
                position: relative;

                // cake
                .cake {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    left: 0;

                    &.is-active {
                        opacity: 0;
                        animation: show 400ms ease-in-out forwards;

                        @keyframes show {
                            0%{opacity: 0;}
                            100%{opacity: 1;}
                        }

                    }

                    &.no-4 {
                        left: -28px;
                    }

                }

                // target
                .target {
                    position: absolute;
                    opacity: 0;

                    &.is-active {
                        opacity: 0;
                        animation: show 600ms ease-in-out forwards;

                        @keyframes show {
                            0%{opacity: 0;}
                            100%{opacity: 1;}
                        }

                    }

                    &.no-1 {
                        top: 100px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 195px;
                        height: 115px;
                    }
                    &.no-2 {
                        top: 215px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 235px;
                        height: 115px;
                    }
                    &.no-3 {
                        top: 330px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 280px;
                        height: 115px;
                    }
                    &.no-4 {
                        bottom: 0;
                        left: 0;
                        width: 362px;
                        height: 430px;
                    }
                    &.no-5 {
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 60px;
                        height: 60px;
                    }

                }
            }

        }

        // cake-mobile
        .cake-mobile {
            display: none;
            margin: 0 auto;
            padding: 0;
            margin-bottom: 40px;
            width: 100%;
            height: auto;

            img {
                display: block;
                margin: 0 auto;
                width: auto;
                height: 352px;


                @include big {
                    max-width: 260px;
                }
            }

            @include big {
                display: block;
            }

        }

    }

    // slider navigation
    .slick-dots {
        margin-left: -20px;
        margin-right: -20px;

        li {
            margin: 0 20px;
            &.slick-active {
                .slider-dot {
                    background: $grass-green;
                }
            }
            .slider-dot {
                display: inline-block;
                width: 42px;
                height: 7px;
                background: $tmp-6;
                transition: all 200ms ease-in-out;

                &:hover {
                    background: $grass-green;
                }
            }
        }
    }
}
