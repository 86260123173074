.m-footer {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	background-color: $ink-spot;

	// container
	.container {
		max-width: 1080px;
		padding-top: $section-padding;
		padding-bottom: $section-padding;

		@include big {
			padding-top: $section-padding-mobile;
			padding-bottom: $section-padding-mobile;
		}
	}

	// footer-top
	.footer-top {
		position: relative;
		color: $white-color;
		display: flex;
		padding-bottom: 37px;

		@include big {
			flex-wrap: wrap;
			padding-bottom: 41px;
		}

		@include small {
			padding-bottom: 52px;
		}

		//figure
		figure {
			width: 239px;
			box-sizing: border-box;

			@include big {
				width: 100%;
			}

			img {
				display: block;
				margin: 0;
				padding: 0;
				margin-bottom: 26px;

				@include big {
					margin-bottom: 12px;
				}
			}
			figcaption {
				font-family: $real-headline, ui-sans-serif, sans-serif;
				font-size: 22px;
				font-weight: 700;
				letter-spacing: 0;
				line-height: 29px;
				position: relative;

				@include medium {
					font-size: 18px;
					line-height: 26px;
				}

				span {
					display: block;
				}
			}
		}

		// content-list
		.content-list {
			position: relative;
			display: flex;
			width: 80%;
			justify-content: space-between;

			@include big {
				width: 100%;
				margin-top: 40px;
			}

			@include small {
				flex-wrap: wrap;
				margin-top: 10px;
			}

			// col
			.col {
				// 2023-09-29 Adjusted for three cols 1050/4 = 262
				max-width: 260px;
				//max-width: 152px;
				margin-left: 100px;
				//margin-left: 60px;

				@include big {
					//margin-left: 0;
					margin-left: 50px;

					&:first-child {
						margin-left: 0;
					}

					&.col-language {
						width: 80px;
					}
				}

				@include small {
					width: 50%;
					max-width: none;
					margin-left: 0;
					margin-top: 27px;
				}

				// 2023-09-29 Added
				@include xsmall {
					width: 100%;
					max-width: none;
					margin-left: 0;
					margin-top: 27px;
				}
			}

			//headline-footer
			.headline-footer {
				display: inline;
				font-family: $real-headline;
				font-size: 12px;
				font-weight: 300;
				letter-spacing: 0.92px;
				line-height: 16px;
				text-transform: uppercase;
			}

			// list
			ul.list {
				margin-top: 24px;

				@include big {
					margin-top: 19px;
				}
			}
			ul.list li {
				display: block;
				margin: 3px 0;

				a {
					position: relative;
					display: inline;
					font-size: 13px;
					line-height: 26px;
					font-weight: 300;
					letter-spacing: 0;
					padding: 5px 0;
					width: calc(100%);
					background-image: linear-gradient(
						transparent calc(100% - 2px),
						$grass-green 2px
					);
					background-repeat: no-repeat;
					background-size: 0% 100%;
					transition: background-size 300ms ease-in-out;

					&:hover {
						background-size: 100% 100%;
					}
				}
			}

			// switcher-language
			// 			ul.switcher-language {
			// 				> li {
			// 					display: none;
			//
			// 					> a {
			// 						display: inline;
			// 						position: relative;
			// 						cursor: default;
			// 					}
			//
			// 					&.current-menu-parent {
			// 						display: block;
			// 						margin-bottom: 24px;
			//
			// 						> a {
			// 							font-family: $real-headline;
			// 							font-size: 12px;
			// 							font-weight: 300;
			// 							letter-spacing: 0.92px;
			// 							line-height: 16px;
			// 							text-transform: uppercase;
			//
			// 							&:after {
			// 								content: "";
			// 								position: absolute;
			// 								top: 50%;
			// 								right: -15px;
			// 								transform: translateY(-50%);
			// 								background-image: url("../img/arrow-dropdown-white.svg");
			// 								background-repeat: no-repeat;
			// 								width: 11px;
			// 								height: 7px;
			// 							}
			// 						}
			// 					}
			// 				}
			//
			// 				.sub-menu {
			// 					margin-top: 24px;
			// 					display: none;
			//
			// 					@include big {
			// 						margin-top: 19px;
			// 					}
			//
			// 					li {
			// 						display: block;
			// 						margin: 3px 0;
			//
			// 						a {
			// 							position: relative;
			// 							display: inline;
			// 							font-size: 13px;
			// 							line-height: 26px;
			// 							font-weight: 300;
			// 							letter-spacing: 0;
			// 							padding: 5px 0;
			// 							width: calc(100%);
			// 							background-image: linear-gradient(
			// 								transparent calc(100% - 2px),
			// 								$grass-green 2px
			// 							);
			// 							background-repeat: no-repeat;
			// 							background-size: 0% 100%;
			// 							transition: background-size 300ms ease-in-out;
			// 							text-transform: capitalize !important;
			//
			// 							&:hover {
			// 								background-size: 100% 100%;
			// 							}
			// 						}
			// 					}
			// 				}
			// 			}
		}
	}

	// footer-bottom
	.footer-bottom {
		position: relative;
		color: $white-color;
		display: flex;
		border-top: 1px solid #f1f2f7;
		padding-top: 37px;
		// justify-content: space-between;
		// align-items: center;

		@include big {
			padding-top: 26px;
		}

		@include small {
			padding-top: 40px;
			display: block;
		}

		//icon-list
		.icon-list {
			flex: 1; /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
			display: flex;
			justify-content: flex-start;
			align-items: center;

			@include small {
				margin-bottom: 2em;
				justify-content: center;
			}

			a {
				display: block;
				position: relative;
				background-color: $grass-green;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				margin-right: 18px;

				@include small {
					margin: 0 9px;
				}

				&:hover {
					background-color: $grass-green-75;
				}

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		// newsletter = footer bottom center
		.newsletter {
			//margin: 0 -7px;
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;

			@include small {
				margin: 2.4em auto 2em auto;
				text-align: center;
			}

			li {
				display: inline-block;

				&:not(:last-child):after {
					content: "";
					position: absolute;
					top: 50%;
					right: -2px;
					transform: translateY(-50%);
					height: 13px;
					width: 1px;
					background: $white-color;
				}

				a {
					border-radius: 19px;
					text-decoration: none;
					transition: all 200ms ease-in-out;
					position: relative;
					display: inline;
					font-size: 13px;
					line-height: 26px;
					font-weight: 400;
					letter-spacing: 0;
					margin: 0 7px;
					padding: 5px 0;
					width: calc(100%);
					color: $white-color;
					background-color: $grass-green;
					padding: 10px 20px;

					&:hover {
						background-color: $grass-green-75;
					}
				}
			}
		}

		//terms
		.terms {
			margin: 0 -7px;
			flex: 1;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@include small {
				display: block;
				margin: 0 auto;
				text-align: center;
			}

			li {
				position: relative;
				display: inline-block;

				&:not(:last-child):after {
					content: "";
					position: absolute;
					top: 50%;
					right: -2px;
					transform: translateY(-50%);
					height: 13px;
					width: 1px;
					background: $white-color;
				}

				a {
					position: relative;
					display: inline;
					font-size: 13px;
					line-height: 26px;
					font-weight: 300;
					letter-spacing: 0;
					margin: 0 7px;
					padding: 5px 0;
					width: calc(100%);
					background-image: linear-gradient(
						transparent calc(100% - 2px),
						$grass-green 2px
					);
					background-repeat: no-repeat;
					background-size: 0% 100%;
					transition: background-size 300ms ease-in-out;

					&:hover {
						background-size: 100% 100%;
					}
				}
			}
		}
	}
}
