.m-integrations-diagram {

    // container
    .container {
        display: flex;
        justify-content: space-between;
        padding-top: 136px;
        padding-bottom: 136px;

        @include big {
            padding-bottom: 65px;
            padding-top: 65px;
        }

    }

    // col
    .col {
        position: relative;

        &:first-child {
            max-width: 520px;
            padding-left: 75px;
            margin-top: 110px;

            @include big {
                max-width: 100%;
                padding-left: 0;
                margin: 0 auto;
                margin-top: 0;
                text-align: center;
                width: 100%;
                max-width: 470px;
            }
        }

        &:last-child {
            padding-right: 65px;

            @include big {
                display: none;
            }
        }
    }

    // icon
    .icon {
        display: block;
        margin: 0;
        padding: 0;
        width: 48px;
        height: 52px;
        margin-bottom: 32px;
        position: relative;

        @include medium {
            margin: 0 auto;
            margin-bottom: 24px;
            width: 38px;
            height: 38px;
        }

        .mfiDot2,
        .mfiDot3 {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    // headline
    h3 {
        margin-bottom: 23px;

        @include big {
            margin-bottom: 14px;
        }
    }

    // content-mobile
    .content-mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        margin-top: 24px;

        @media(min-width:1280px) {
            display: none;
        }

        @include small {
            justify-content: center;
        }

        // logotype
        a.icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            margin: 12px 10px;
            border-radius: 50%;
            box-shadow: 0 5px 12px 0 rgba(44,53,81,0.12), 0 12px 44px 0 rgba(44,53,81,0.12);
            width: 80px;
            height: 80px;
            transition: all 300ms ease-in-out;

            &:hover {
                transform: scale(1.2);
            }

            @include small {
                margin: 15px;
            }

            img {
                width: 45px;
                height: auto;
            }
        }
    }

    // link
    .u-button {
        margin-top: 63px;

        @include big {
            margin-top: 36px;
        }

        @include small {
            margin-top: 43px;
        }
    }

    // content
    .content {
        position: relative;
        width: 585px;
        height: 585px;

        // circle
        img.circle {
            display: block;
            margin: 0;
            padding: 0;
        }

        // logotype
        a.icon {
            position: absolute;
            background: #fff;
            border-radius: $white-color;
            border-radius: 50%;
            box-shadow: 0 5px 12px 0 rgba(44,53,81,0.12), 0 12px 44px 0 rgba(44,53,81,0.12);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 300ms ease-in-out;

            &:hover {
                transform: scale(1.2);
            }

            img {
                position: absolute;
                display: block;
                margin: 0;
                padding: 0;
            }

            &.no-1 {
                top: 26px;
                left: 64px;
                width: 90px;
                height: 90px;
            }

            &.no-2 {
                top: 57px;
                right: 118px;
                width: 111px;
                height: 111px;
            }

            &.no-3 {
                top: 180px;
                left: 115px;
                width: 100px;
                height: 100px;
            }

            &.no-4 {
                top: 192px;
                right: -12px;
                width: 60px;
                height: 60px;
            }

            &.no-5 {
                top: 257px;
                right: 179px;
                width: 101px;
                height: 101px;
            }

            &.no-6 {
                bottom: 150px;
                left: 67px;
                width: 76px;
                height: 76px;
            }

            &.no-7 {
                bottom: 45px;
                right: 7px;
                width: 143px;
                height: 143px;
            }

            &.no-8 {
                bottom: -37px;
                left: 173px;
                width: 109px;
                height: 109px;
            }

        }

    }

}
