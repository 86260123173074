// custom post type
.m-cpt  {

    // container
    .container {
        padding-top: 136px;
        padding-bottom: 136px;

        @include big {
            padding-top: 65px;
            padding-bottom: 0;
        }
    }

    // headline
    h3 {
        margin-bottom: 85px;

        @include big {
            font-size: 30px;
            margin-bottom: 35px;
        }
    }

    // wrapper
    .wrapper-cpt {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        &.customer {
            @include big {
                flex-direction: column;
                flex-wrap: wrap;
            }

            // shape
            .shape-left {
                position: absolute;
                bottom: 750px;
                left: 0;

                @include big {
                    display: none;
                }
            }

            .shape-right {
                position: absolute;
                bottom: 300px;
                right: 0px;
                opacity: 0;

                &.loaded {
                    bottom: 300px;
                    right: -280px;
                    opacity: 1;
                }

                @include big{
                    display: none;
                }
            }
        }
    }

}
