.u-bio {
    position: relative;
    margin-top: 60px;
    margin-bottom: 15px;
    padding: 0 40px;
    width: 25%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include big {
        padding: 0 20px;
        margin-top: 45px;
        margin-bottom: 15px;
    }

    @include medium {
        width: 50%;
    }

    @include xsmall {
        width: 100%;
        align-items: flex-start;
    }

    // content
    .content {
        position: relative;
        max-width: 200px;
        z-index: 20;

        // headline
        h6 {
            position: relative;
            margin-top: 25px;
            margin-bottom: 18px;

            @include big {
                font-size: 20px;
                line-height: 24px;
            }

            &:before {
                content: '';
                position: absolute;
                top: -20px;
                left: 0;
                width: 40px;
                height: 5px;
                background: $grass-green;
            }
        }

        // link
        .u-link {
            margin-top: 23px;
            margin-left: -18px;
            cursor: pointer;
        }
    }
}
