.m-statistics {

    &.content-white {
        .headline,
        .text-center,
        .tsp {
            color: $white-color!important;
        }
    }

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // icon
    .icon {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 48px;
        height: 52px;
        margin-bottom: 30px;
        position: relative;

        @include medium {
            width: 38px;
            height: 38px;
        }

        .mfiDot2,
        .mfiDot3 {
            position: absolute;
            top: 0;
            left: 0;
        }
    }


    // headline
    .headline {
        max-width: 900px;
        margin: 0 auto;
        margin-bottom: 80px;

        @include big {
            margin-bottom: 65px;
        }
        
        h3 {
            font-size: 58px;
            line-height: 70px;
            
            @include medium {
                font-size: 48px;
            }
        }
    }

    // text
    .tsp {
        margin-top: 20px;
    }

    // wrapper
    .wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        // margin-bottom: -40px;

        &.module {
            margin: -20px;
        }
    }

    // buttons
    .buttons {
        margin: 0 auto;
        margin-top: 65px;
        text-align: center;
    }

}
