html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: border-box;
    outline: 0;
}

*:after, *:before {
    box-sizing: border-box;
}

ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

p, blockquote, figure {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    // font-size: inherit;
    // font-weight: inherit;
}

a {
    background-color: transparent;
    text-decoration: none;
    color: inherit;
}

img {
    border: none;
}

picture, picture img {
    display: block;
}

/* forms */

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

input, textarea, select, button {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
    line-height: inherit;
    font: inherit;
    color: inherit;
}

input:not([type="button"]):not([type="submit"]):not([type="checkbox"]):not([type="radio"]), textarea {
    -webkit-appearance: none;
}

textarea {
    overflow: auto;
    resize: vertical;
}

button, input[type="button"], input[type="submit"] {
    overflow: visible;
    cursor: pointer;
    -webkit-appearance: button;
}

::placeholder {
    opacity: 1;
    color: inherit;
}

/* blockquotes */

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: none;
}

/* tables */

table {
    border-collapse: collapse;
    border-spacing: 0;
}
