.u-news {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -20px;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 0;
		height: 0;
		background: transparent;
		-webkit-transition: background 0.3s ease;
		-moz-transition: background 0.3s ease;
		-ms-transition: background 0.3s ease;
		-o-transition: background 0.3s ease;
		transition: background 0.3s ease;
	}

	&--loading:after {
		width: 100%;
		height: 100%;
		background: rgba(249, 249, 252, 0.7);
		z-index: 10;
	}

	@include big {
		justify-content: flex-start;
	}

	// col
	.col {
		padding: 0 20px;
		width: 33.333%;

		@include big {
			width: 50%;
			margin-top: 20px;
			padding: 0 20px;
		}

		@include small {
			width: 100%;
		}
	}

	.box {
		padding: 15px 15px 30px;
		background: transparent;
		transition: all 200ms ease-in-out;
		height: 100%;

		&:hover {
			background: $white-color;
			box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.12),
				0 10px 40px 0 rgba(44, 53, 81, 0.12);

			.logotype {
				filter: grayscale(0);
			}
		}

		// image
		.box-img,
		.wp-post-image {
			display: block;
			margin: 0 auto;
			padding: 0;
			object-fit: cover;
			object-position: top center;
			width: 100%;
			height: 215px;
		}

		// logotype
		.logotype {
			display: block;
			margin: 0;
			margin-top: 20px;
			margin-bottom: 10px;
			padding: 0;
			width: auto;
			height: 20px;
			filter: grayscale(1);
			transition: all 200ms ease-in-out;
		}

		// subtitle
		.subtitle {
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 1.71px;
			margin-top: 30px;
		}

		// headline
		h6 {
			margin: 10px 0 15px 0;
		}
	}
}
