.m-steps {
    background-color: $ink-spot;

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }


    // headline
    .headline {
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 65px;
    }

    // text
    .tsp {
        margin-top: 20px;
        color: $white-color;
    }

    h3 {
        color: $white-color;
    }

    // wrapper
    .wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -20px -40px;

        @include small {
            margin-bottom: 0;
        }
    }

}
