abbr {
    font-style: italic;
}

abbr[title], acronym[title] {
    text-decoration: none;
    cursor: help;
    border-bottom: 1px dotted #777;
}


// .thidden[role="tooltip"] {
//     display: none;
// }
// [role="tooltip"] {
//     position: absolute;
//     z-index: 12;
//     left: 0;
//     bottom: -30px;
//     width: auto;
//     white-space: nowrap;
//     background-color: #1e1e1e;
//     color: #fff;
//     border-radius: 3px;
//     box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
//     font-size: 14px;
//     padding: 3px 5px;
// }