.m-table {
  .container {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    @include big {
      padding-top: $section-padding-mobile;
      padding-bottom: $section-padding-mobile;
    }

      // 2024-01-16
      .content {
          margin: 1.5em auto 1em auto;
          max-width: 1050px;
      }
  }
}

.m-table__container {
  overflow: hidden;
}

.m-table__wrapper {
  overflow-x: scroll;
  border: 2px solid rgb(200, 200, 200);
};

.m-table__table {
  border-collapse: collapse;
  font-size: 1em;
  line-height: 1.5;
  width: 100%;
  text-align: left;
  word-wrap: break-word;

  td,th {
    border: 1px solid #ececec;
    padding: 10px 20px;
    word-wrap: break-word;
  }

  th {
    color: white;
    background-color: #2c3551;
  }

  tr:nth-child(even) td {
    background-color: rgb(252, 252, 252);
  }

  tr:nth-child(odd) td {
    background-color: rgb(250, 250, 250);
  }
}


