.u-featured-box {
    position: relative;
    margin: 20px 0;
    padding: 0 40px;
    width: 33.333%;
    box-sizing: border-box;

    @include big {
        padding: 0 20px;
    }

    @include small {
        width: 100%;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 40px;
        width: 40px;
        height: 5px;
        background: $grass-green;

        @include big {
            left: 20px;
        }
    }

    // content
    .content {
        position: relative;
        padding-right: 10px;

        // headline
        h6 {
            margin-top: 25px;
            margin-bottom: 18px;

            @include big {
                font-size: 20px;
                line-height: 24px;
            }
        }

        // link
        .u-link {
            margin-top: 23px;
            margin-left: -20px;
        }
    }

    &.half {
        width: 50%;

        @include big {
            padding: 0;
            width: 45%;
        }

        @include small {
            width: 100%;
        }

        &:before {
            @include big {
                left: 0;
            }
        }
    }
}
