.u-box-simple {
    position: relative;
    width: 33.333%;
    text-align: center;
    box-sizing: border-box;
    margin: 20px;
    padding: 0;
    width: calc(33.33% - 40px);

    @include medium {
        width: calc(50% - 40px);
    }

    @include small {
        width: 100% !important;

        &:first-child {
            margin-top: 0;
        }
    }

    &.size-25 {
        width: calc(25% - 40px);
    }

    &.size-50 {
        width: calc(50% - 40px);
    }

    &.size-100 {
        width: 100% !important;
    }

    &.text-left {
        text-align: left;
    }

    &.text-right {
        text-align: right;
    }

    // box
    .box {
        position: relative;
        border: 3px solid $grass-green;
        border-radius: 10px;
        height: 100%;
    }

    &.border-grey .box {
        border-color: $ink-spot-25;
    }

    // content
    .content {
        padding: 40px 20px 40px 20px;

        h6 {
            margin-bottom: 20px;
        }

    }

}
