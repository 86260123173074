.m-offices {
    overflow: hidden;

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // title
    .title {
        display: block;
        padding: 0 20px;
        margin-bottom: 35px;

        @include medium {
            margin-bottom: 20px;
        }
    }

    // top
    .top {

        // buttons
        .buttons {
            margin: 0 auto;
            margin-top: 65px;
            text-align: center;
        }

        // wrapper
        .wrapper {
            position: relative;
            margin: 0 auto;
            display: flex;
            justify-content: flex-end;
            max-width: 980px;

            @include medium {
                max-width: none;
                justify-content: center;
            }
        }

        // content
        .content {
            position: absolute;
            top: 170px;
            right: 120px;
            width: 245px;

            @include medium {
                top: 360px;
                left: 50%;
                transform: translateX(-50%);
                right: unset;
            }

            &.light {
                color: $white-color;

                h5 {
                    color: $white-color;
                }

                p {
                    color: $white-color!important;
                }
            }

            // text
            p {
                display: block;
                margin-top: 20px;
            }

            // button
            .u-link {
                margin-top: 30px;
                margin-left: -18px;
            }

        }

        // img
        .office-img {
            position: absolute;
            top: 120px;
            left: 0;
            z-index: 10;

            @include big {
                max-width: 490px;
            }

            @include medium {
                top: 35px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 100%;
                padding: 0 10px;
                height: 300px;

            }
        }

        // background wrapper
        .wrapper-bg {
            position: relative;

            @include medium {
                top: 40px;
                margin-top: 145px;
            }

            .blob-bg {
                margin: 0;
                padding: 0;
                display: block;
            }

        }

    }

    // bottom
    .bottom {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0 -40px;
        box-sizing: border-box;

        @include big {
            margin: 0;
        }

        @include medium {
            justify-content: flex-start;
        }

        // illustration
        .shape {
            position: absolute;
            bottom: -75px;
            right: -25px;
            z-index: 10;

            @include big {
                display: none;
            }

            #rotatingShapeOffices {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

    }

}
