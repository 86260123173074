.u-step {
    position: relative;
    width: 33.333%;
    text-align: center;
    box-sizing: border-box;
    margin: 20px 0;
    margin-bottom: 40px;
    padding: 0 20px;

    @include medium {
        width: 50% !important;
    }

    @include small {
        width: 100% !important;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.size-25 {
        width: 25%;
    }

    &.size-50 {
        width: 50%;
    }

    &.size-100 {
        width: 100% !important;
    }

    // box
    .box {
        position: relative;
        border: 3px solid $grass-green;
        border-radius: 10px;
        height: 100%;
    }

    // data-healdine
    .data-headline {
        position: absolute;
        width: 100%;
        left: 0;
        top: -35px;
        text-align: center;
        z-index: 10;

        // data
        .data {
            display: inline-block;
            background-color: $ink-spot;
            padding: 0 20px;

            // value
            .value {
                font-size: 60px;
                font-family: $real-headline;
                color: $white-color;
            }

        }

    }

    // content
    .content {
        padding: 40px 20px 40px 20px;

        h6 {
            margin-bottom: 20px;
        }

        h6, p {
            color: $white-color;
        }
    }

}
