.m-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 85px;
	background-color: $white-color;
	z-index: 100;

	&.scrolled {
		box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.1),
			0 10px 40px 0 rgba(44, 53, 81, 0.07);
	}

	body.admin-bar & {
		top: 32px;

		@media (max-width: 782px) {
			top: 46px;
		}
	}

	@media (max-width: 1200px) {
		height: 60px;
	}

	// btn-mobile
	.btn-mobile {
		display: none;
		position: absolute;
		top: 22px;
		right: 38px;
		cursor: pointer;
		z-index: 10;
		transition: all 200ms ease-in-out;

		@include small {
			right: 20px;
		}

		@media (max-width: 1200px) {
			display: block;
		}

		span {
			margin: 0;
			padding: 0;
			display: block;
			width: 23px;
			height: 2px;
			background-color: $black-color;
			margin-bottom: 4px;
			transition: all 200ms ease-in-out;
		}
	}
	.btn-mobile span:nth-child(2) {
		width: 16px;
		margin-left: 7px;
	}
	.btn-mobile span:nth-child(3) {
		margin-bottom: 0;
	}
	.btn-mobile span.x:nth-child(1) {
		transform: rotate(-45deg) translate(-5px, 4px);
	}
	.btn-mobile span.x:nth-child(2) {
		opacity: 0;
	}
	.btn-mobile span.x:nth-child(3) {
		transform: rotate(45deg) translate(-4px, -4px);
	}

	//logo
	.logo {
		position: absolute;
		top: 30px;
		left: 15px;

		@media (max-width: 1200px) {
			top: 18px;
			left: 40px;
		}

		@include small {
			left: 20px;
		}

		img {
			width: 170px;
			height: auto;

			@media (max-width: 1200px) {
				width: 142px;
				height: 24px;
			}
		}
	}

	//forrester logo 2023-05-25
	.forrester-logo {
		position: absolute;
		top: 30px;
		right: 15px;

		@media (max-width: 1200px) {
			top: 18px;
			right: 40px;
		}

		@include small {
			right: 20px;
		}

		img {
			width: 170px;
			height: auto;

			@media (max-width: 1200px) {
				width: 142px;
				height: 24px;
			}
		}
	}

	//menu-left
	.menu-left {
		position: absolute;
		top: 25px;
		left: 296px;
		margin-left: -53px;

		@media (max-width: 1200px) {
			display: none;
		}

		> ul > li {
			position: relative;
			font-size: 15px;
			display: inline-block;
			line-height: 40px;
			margin-right: 18px;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: -1px;
				width: 0;
				height: 5px;
				background-color: $grass-green;
				transition: all 300ms ease-in-out;
				z-index: 20;
			}

			&:hover:after {
				width: calc(100% - 18px);
			}

			> a {
				display: block;
				margin: 0;
				padding: 0 0 20px 18px;
			}
		}

		> ul > li.menu-item-has-children {
			.sub-menu {
				position: absolute;
				max-height: 0;
				overflow: hidden;
				top: 60px;
				left: -5px;
				background-color: $white-color;
				box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.12),
					0 10px 40px 0 rgba(44, 53, 81, 0.12);
				transition: all 400ms ease-in-out;

				li {
					line-height: 20px;
					margin-bottom: 10px;
					padding: 0 30px;

					&:nth-child(1) {
						margin-top: 25px;
					}
					&:last-child {
						margin-bottom: 20px;
					}

					a {
						position: relative;
						color: $ink-spot;
						font-size: 14px;
						display: inline;
						line-height: 26px;
						letter-spacing: 0;
						padding: 5px 0;
						width: calc(100%);
						background-image: linear-gradient(
							transparent calc(100% - 2px),
							$grass-green 2px
						);
						background-repeat: no-repeat;
						background-size: 0% 100%;
						transition: background-size 300ms ease-in-out;
						white-space: nowrap;

						&:hover {
							background-size: 100% 100%;
						}
					}
				}
			}

			&:hover .sub-menu {
				max-height: 400px;
				z-index: 10;
			}
		}

		> ul > li.megamenu {
			//mega-menu
			.mega-menu {
				display: flex;
				max-height: 0;
				overflow: hidden;
				position: absolute;
				top: 60px;
				left: -33px;
				background-color: $white-color;
				box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.12),
					0 10px 40px 0 rgba(44, 53, 81, 0.12);
				transition: all 400ms ease-in-out;

				// box
				.box {
					min-width: 300px;
					display: block;
					margin: 0;
					padding: 0;
					padding: 37px 16px 37px 45px;
					box-sizing: border-box;

					&:nth-child(2) {
						padding-left: 45px;
						border-right: 1px solid #ddd;
						border-left: 1px solid #ddd;
					}

					&.post {
						background-color: $tmp-4;
						min-width: 325px;
						padding-left: 55px;
						padding-right: 55px;

						img {
							margin-bottom: 20px;
							max-width: 225px;
						}
					}
				}

				span.menu-headline {
					display: block;
					font-family: $real-headline, ui-sans-serif, sans-serif;
					font-weight: 400;
					font-size: 14px;
					color: #b2aeae;
					line-height: 20px;
					text-transform: uppercase;
					letter-spacing: 1.4px;
					margin-bottom: 20px;
				}

				ul > li {
					line-height: 25px;
					margin-bottom: 20px;

					> a {
						color: #999;
						position: relative;
						color: $ink-spot;
						font-size: 15px;
						line-height: 26px;
						display: inline;
						vertical-align: top;
						letter-spacing: 0;
						padding: 5px 0;
						width: calc(100%);
						background-image: linear-gradient(
							transparent calc(100% - 2px),
							$grass-green 2px
						);
						background-repeat: no-repeat;
						background-size: 0% 100%;
						transition: all 300ms ease-in-out;

						&:hover {
							background-size: 100% 100%;
							color: #000;
						}

						// refactored 2023-08-29
						span.description {
							display: inline-block;
							margin: 2px 10px 0 36px;
							font-size: 12px;
							font-weight: 300;
							line-height: 1.4;
						}
					}
				}

				ul > li.divider {
					margin-top: 1.25rem;
					padding-top: 1.25rem;
					border-top: 1px solid #ddd;
				}

				ul > li.link-highlight a {
					padding-left: 0;
					color: #ec673a;
				}

				ul > li.manufacturing a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Manufacturing.svg");
					background-size: 21px 21px;
					//background-position: 0 4px;
				}

				ul > li.healthcare a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Healthcare.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}

				ul > li.retail a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Retail.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}

				ul > li.howitworks a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_How-it-works.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}

				ul > li.features a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Features.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.promotedfeature a,
				ul > li.productdemo a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Promoted-Feature.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}

				ul > li.small-businesses a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Small-Business.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.mid-market a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Mid-Market.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.enterprise a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Enterprise.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}

				//2024-08-basis-28
				ul > li.events a {
					padding-left: 36px;
					background-image: url("../img/nav/Ikoner-Navigation_Events.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.bankid a {
					padding-left: 36px;
					background-image: url("../img/integrationer/Integrationer_BankID.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.microsoftteams a {
					padding-left: 36px;
					background-image: url("../img/integrationer/Integrationer_Teams.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.salesforce a {
					padding-left: 36px;
					background-image: url("../img/integrationer/Integrationer_Salesforce.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.hubspot a {
					padding-left: 36px;
					background-image: url("../img/integrationer/Integrationer_Hubspot.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.dynamics365 a {
					padding-left: 36px;
					background-image: url("../img/integrationer/Integrationer_Dynamics365.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.zendesk a {
					padding-left: 36px;
					background-image: url("../img/integrationer/Integrationer_Zendesk.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.powerbi a {
					padding-left: 36px;
					background-image: url("../img/integrationer/Integrationer_PowerBI.svg");
					background-size: 21px 21px;
					background-position: 0 4px;
				}
				ul > li.seeallintegrations a {
					padding-left: 0;
					color: #ec673a;
				}
				ul > li.seeallintegrations a:hover {
					//color: #903e23;
				}
			}

			&:hover .mega-menu {
				max-height: 400px;
				z-index: 10;
			}
		}
	}

	// menu right
	.menu-right {
		position: absolute;
		top: 25px;
		right: 18px;
		display: flex;

		align-items: center;

		@media (max-width: 1200px) {
			display: none;
		}

		// links
		.menu-help {
			margin-right: 22px;

			li {
				position: relative;
				font-size: 14px;
				line-height: 40px;
				display: inline-block;
				margin-left: 27px;

				&:after {
					content: "";
					position: absolute;
					bottom: 28px;
					left: 0;
					width: 0;
					height: 2px;
					background-color: $grass-green;
					transition: all 300ms ease-in-out;
					z-index: 20;
				}

				&:hover:after {
					width: 100%;
				}

				a {
					display: block;
					margin: 0;
					padding: 0 0 20px 0;
				}
			}
		}

		// menu langauge
		ul.menu-language {
			> li {
				position: relative;
				display: none;
				font-size: 14px;
				line-height: 40px;
				padding: 0 10px 0 5px;

				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 14px;
					width: 0;
					height: 5px;
					background-color: $grass-green;
					transition: all 300ms ease-in-out;
					z-index: 20;
				}

				&:hover:after {
					width: 80%;
				}

				&:hover .sub-menu {
					max-height: 400px;
				}

				&.current-menu-parent {
					display: block;
					cursor: default;

					> a {
						display: block;
						margin: 0;
						padding: 0 14px 20px 9px;
						position: relative;
						cursor: default;

						&:after {
							content: "";
							position: absolute;
							top: 16px;
							right: 0;
							background-image: url("../img/arrow-dropdown.svg");
							background-repeat: no-repeat;
							width: 11px;
							height: 7px;
						}
					}
				}

				.sub-menu {
					position: absolute;
					max-height: 0;
					min-width: 193px;
					overflow: hidden;
					top: 60px;
					left: -5px;
					background-color: $white-color;
					box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.12),
						0 10px 40px 0 rgba(44, 53, 81, 0.12);
					transition: all 400ms ease-in-out;

					li {
						line-height: 20px;
						margin-bottom: 10px;
						padding: 0 30px;

						&:nth-child(1) {
							margin-top: 25px;
						}
						&:last-child {
							margin-bottom: 20px;
						}

						a {
							position: relative;
							color: $ink-spot;
							font-size: 14px;
							display: inline;
							line-height: 26px;
							letter-spacing: 0;
							// 2023-08-18 pevent line breaks in language picker options
							//white-space: nowrap;
							padding: 7px 0 0 0;
							//width: calc(100%);
							background-image: linear-gradient(
								transparent calc(100% - 2px),
								$grass-green 2px
							);
							background-repeat: no-repeat;
							background-size: 0% 100%;
							transition: background-size 300ms ease-in-out;

							&:hover {
								background-size: 100% 100%;
							}
						}
					}
				}
			}
		}

		// button
		.u-button {
			margin-left: 38px;
			align-self: baseline;
		}
	}
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// menu-mobile
	.menu-mobile {
		display: none;
		position: fixed;
		top: 60px;
		left: 0;
		bottom: 0;
		width: 100%;
		min-height: 100vh;
		background-color: $white-color;
		transition: all 200ms ease-in-out;
		z-index: 10;
		overflow-y: auto;

		body.admin-bar & {
			top: 92px;

			@media (max-width: 782px) {
				top: 106px;
			}
		}

		&.block {
			display: block;
		}

		// menu-top
		.menu-top {
			position: relative;
			margin-top: 18px;

			// links
			> ul > li {
				display: block;
				margin: 0;
				padding: 0;
				border-bottom: 1px solid #ebebeb;

				&:last-child {
					border-bottom: none;
				}

				> a {
					position: relative;
					display: block;
					padding: 14px 40px;
					font-size: 18px;
					line-height: 23px;

					@include small {
						padding: 14px 29px;
					}
				}

				// li element
				&.megamenu {
					> a {
						position: relative;
						display: block;
						padding: 14px 40px;
						font-size: 18px;
						line-height: 23px;

						@include small {
							padding: 14px 29px;
						}

						&:after {
							content: "";
							position: absolute;
							top: 50%;
							right: 40px;
							transform: translateY(-50%);
							background-image: url("../img/arrow-dropdown-mobile.svg");
							background-repeat: no-repeat;
							width: 17px;
							height: 10px;
							transition: all 200ms ease-in-out;

							@include small {
								right: 20px;
							}
						}

						&.is-active {
							&:after {
								content: "";
								position: absolute;
								top: 50%;
								right: 40px;
								transform: translateY(-50%) rotate(180deg);
								background-image: url("../img/arrow-dropdown-mobile.svg");
								background-repeat: no-repeat;
								width: 17px;
								height: 10px;
								transition: all 200ms ease-in-out;

								@include small {
									right: 20px;
								}
							}
						}
					}

					ul > li a span.description {
						display: inline-block;
						margin: 2px 10px 0 36px;
						font-size: 12px;
						font-weight: 300;
						line-height: 1.4;
					}

					ul > li.link-highlight a {
						padding-left: 0;
						color: #ec673a;
					}

					ul > li.divider {
						margin-top: 1.25rem;
						padding-top: 1.25rem;
						border-top: 1px solid #ddd;
					}

					ul > li.manufacturing a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_Manufacturing.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}

					ul > li.healthcare a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_Healthcare.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}

					ul > li.retail a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_Retail.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}

					ul > li.howitworks a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_How-it-works.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.features a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_Features.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.promotedfeature a,
					ul > li.productdemo a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_Promoted-Feature.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}

					ul > li.small-businesses a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_Small-Business.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.mid-market a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_Mid-Market.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.enterprise a {
						padding-left: 36px;
						background-image: url("../img/nav/Ikoner-Navigation_Enterprise.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}

					ul > li.bankid a {
						padding-left: 36px;
						background-image: url("../img/integrationer/Integrationer_BankID.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.powerbi a {
						padding-left: 36px;
						background-image: url("../img/integrationer/Integrationer_PowerBI.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.microsoftteams a {
						padding-left: 36px;
						background-image: url("../img/integrationer/Integrationer_Teams.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.salesforce a {
						padding-left: 36px;
						background-image: url("../img/integrationer/Integrationer_Salesforce.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.hubspot a {
						padding-left: 36px;
						background-image: url("../img/integrationer/Integrationer_Hubspot.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.dynamics365 a {
						padding-left: 36px;
						background-image: url("../img/integrationer/Integrationer_Dynamics365.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
					ul > li.zendesk a {
						padding-left: 36px;
						background-image: url("../img/integrationer/Integrationer_Zendesk.svg");
						background-size: 21px 21px;
						background-position: 0 4px;
					}
				}
			}

			// dropdown list
			li.menu-item-has-children {
				> a {
					position: relative;
					display: block;
					padding: 14px 40px;
					font-size: 18px;
					line-height: 23px;

					@include small {
						padding: 14px 29px;
					}

					&:after {
						content: "";
						position: absolute;
						top: 50%;
						right: 40px;
						transform: translateY(-50%);
						background-image: url("../img/arrow-dropdown-mobile.svg");
						background-repeat: no-repeat;
						width: 17px;
						height: 10px;
						transition: all 200ms ease-in-out;

						@include small {
							right: 20px;
						}
					}

					&.is-active {
						&:after {
							content: "";
							position: absolute;
							top: 50%;
							right: 40px;
							transform: translateY(-50%) rotate(180deg);
							background-image: url("../img/arrow-dropdown-mobile.svg");
							background-repeat: no-repeat;
							width: 17px;
							height: 10px;
							transition: all 200ms ease-in-out;

							@include small {
								right: 20px;
							}
						}
					}
				}
			}

			// sub-menu
			.sub-menu {
				display: none;
				padding: 20px 40px 5px 40px;
				background-color: rgba(202, 240, 213, 0.3);

				li {
					line-height: 20px;
					margin-bottom: 14px;
					padding-left: 20px;

					&:last-child {
						margin-bottom: 10px;
					}

					> a {
						position: relative;
						color: $ink-spot;
						font-size: 14px;
						display: inline;
						padding: 5px 0;
						width: calc(100%);
						background-image: linear-gradient(
							transparent calc(100% - 2px),
							$grass-green 2px
						);
						background-repeat: no-repeat;
						background-size: 0% 100%;
						transition: background-size 300ms ease-in-out;

						&:hover {
							background-size: 100% 100%;
						}
					}
				}
			}

			// mega-menu-mobile
			.mega-menu {
				display: none;
				padding: 20px 40px;
				background-color: rgba(202, 240, 213, 0.3);

				.box.post {
					display: none !important;
				}

				@include small {
					padding: 20px 29px;
				}

				// headline
				span.menu-headline {
					display: block;
					font-family: $real-headline;
					font-size: 13px;
					line-height: 18px;
					text-transform: uppercase;
					letter-spacing: 1px;
					margin-top: 20px;
					margin-bottom: 14px;
					margin-left: 20px;
				}

				ul > li {
					line-height: 20px;
					margin-bottom: 14px;
					padding-left: 20px;

					> a {
						position: relative;
						color: $ink-spot;
						font-size: 14px;
						display: inline;
						padding: 5px 0;
						width: calc(100%);
						background-image: linear-gradient(
							transparent calc(100% - 2px),
							$grass-green 2px
						);
						background-repeat: no-repeat;
						background-size: 0% 100%;
						transition: background-size 300ms ease-in-out;

						&:hover {
							background-size: 100% 100%;
						}
					}
				}
			}
		}

		// menu-bottom
		.menu-bottom {
			position: relative;
			padding: 0 40px;
			padding-bottom: 80px;
			margin-top: 30px;
			text-align: center;

			@include small {
				padding: 0 20px;
				padding-bottom: 80px;
			}

			// phone
			p.tel {
				display: block;
				margin-top: 18px;
				font-size: 16px;
				line-height: 23px;

				a {
					color: $happy-red;
				}
			}

			// links
			> ul {
				margin-top: 34px;

				> li {
					display: block;
					margin-bottom: 13px;

					a {
						display: inline-block;
						font-size: 16px;
						line-height: 40px;
					}
				}
			}

			// menu langauge
			.menu-language {
				margin-top: 13px;

				li {
					position: relative;
					display: none;
					font-size: 16px;
					line-height: 40px;

					&.current-menu-parent {
						display: block;

						> a {
							display: inline-block;
							margin: 0;
							margin-left: -10px;
							padding: 0 15px;
							position: relative;
							cursor: pointer;

							&:after {
								content: "";
								position: absolute;
								top: 16px;
								right: 0;
								background-image: url("../img/arrow-dropdown.svg");
								background-repeat: no-repeat;
								width: 11px;
								height: 7px;
							}
						}
					}

					.sub-menu {
						display: none;
						position: relative;

						li {
							display: block;
							margin: 0;
							padding: 0;

							// 2023-08-09 hide nl_BE and fr_BE temporarily from menu
							// #menu-item-347-fr-be,
							// #menu-item-347-nl-be,
							// #menu-item-347-es {
							// 	display: none;
							// }

							a {
								position: relative;
								color: $ink-spot;
								font-size: 14px;
								display: inline;
								padding: 5px 0;
								width: calc(100%);
								background-image: linear-gradient(
									transparent calc(100% - 2px),
									$grass-green 2px
								);
								background-repeat: no-repeat;
								background-size: 0% 100%;
								transition: background-size 300ms ease-in-out;

								&:hover {
									background-size: 100% 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}
//added after 9 brackets of nesting -- modern web dev is insane! -- 2023-02-13

// from options HEAD code 2023-05-11
.m-header .menu-left {
	left: 276px;
}
.m-header .menu-left > ul > li > a {
	display: block;
	margin: 0;
	padding: 0 0 20px 6px;
}
.m-header .menu-left > ul > li:after {
	left: 6px;
}
.m-header .menu-left > ul > li:hover:after {
	width: calc(100% - 6px);
}
.m-header .menu-left > ul > li.megamenu .mega-menu {
	left: -133px;
}

// //added  2023-02-13 + befr 2023-08-22

html:lang(da-DK) .m-header .menu-left {
	//changed 2023-05-29 from -73pxF::after
	margin-left: -53px;
}

html:lang(fi) .m-header .menu-left,
html:lang(fr-BE) .m-header .menu-left,
html:lang(nl-BE) .m-header .menu-left,
html:lang(fr-FR) .m-header .menu-left,
html:lang(es-ES) .m-header .menu-left {
	margin-left: -63px;
}
.m-header .menu-left > ul > li {
	position: relative;
	font-size: 15px;
	display: inline-block;
	line-height: 40px;
	margin-right: 15px;
}
html:lang(fi) .m-header .menu-left > ul > li,
html:lang(fr-BE) .m-header .menu-left > ul > li,
html:lang(nl-BE) .m-header .menu-left > ul > li,
html:lang(fr-FR) .m-header .menu-left > ul > li,
html:lang(es-ES) .m-header .menu-left > ul > li {
	margin-right: 11px;
}

.m-header .menu-left > ul > li:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 7px;
	width: 0;
	height: 5px;
	background-color: #2dc659;
}

html:lang(fr-FR) .m-header .menu-right .menu-help,
html:lang(nl-BE) .m-header .menu-right .menu-help,
html:lang(fr-BE) .m-header .menu-right .menu-help,
html:lang(fi) .m-header .menu-right .menu-help {
	margin-right: 7px;
}
.m-header .menu-right .menu-help li {
	position: relative;
	font-size: 14px;
	line-height: 40px;
	display: inline-block;
	margin-left: 24px;
}

html:lang(nl-BE) .m-header .menu-right .menu-help li,
html:lang(fr-BE) .m-header .menu-right .menu-help li,
html:lang(fr-FR) .m-header .menu-right .menu-help li,
html:lang(fi) .m-header .menu-right .menu-help li {
	margin-left: 14px;
}
.m-header .menu-right .menu-help li:after {
	content: "";
	position: absolute;
	bottom: 28px;
	left: 0;
	width: 0;
	height: 2px;
	background-color: #2dc659;
}

.m-header .menu-right .u-button {
	margin-left: 28px;
	-ms-flex-item-align: baseline;
	align-self: baseline;
}

html:lang(nl-BE) .m-header .menu-right .u-button,
html:lang(fr-BE) .m-header .menu-right .u-button,
html:lang(fr-FR) .m-header .menu-right .u-button,
html:lang(fi) .m-header .menu-right .u-button {
	margin-left: 12px;
}
.m-header .menu-mobile {
	display: none;
	position: fixed;
	top: 60px;
	left: 0;
	bottom: 0;
	width: 100%;
	min-height: 100vh;
	background-color: #fff;
}

html:lang(da-DK)
	.m-header
	.menu-left
	> ul
	> li.megamenu
	.mega-menu
	span.menu-headline,
html:lang(sv-SE)
	.m-header
	.menu-left
	> ul
	> li.megamenu
	.mega-menu
	span.menu-headline,
html:lang(en-US)
	.m-header
	.menu-left
	> ul
	> li.megamenu
	.mega-menu
	span.menu-headline,
html:lang(fr-BE)
	.m-header
	.menu-left
	> ul
	> li.megamenu
	.mega-menu
	span.menu-headline,
html:lang(nl-BE)
	.m-header
	.menu-left
	> ul
	> li.megamenu
	.mega-menu
	span.menu-headline,
html:lang(fr-FR)
	.m-header
	.menu-left
	> ul
	> li.megamenu
	.mega-menu
	span.menu-headline {
	display: none;
}

.m-header .menu-mobile .menu-top .mega-menu span.menu-headline {
	display: none;
}

.m-header .menu-mobile .menu-top .mega-menu span.description {
	margin-top: 9px;
	padding-top: 3px;
	font-size: 1em;
	font-weight: 200;
	color: #888;
}

.m-header .menu-mobile .menu-top .mega-menu li.seeallintegrations a {
	color: #ec673a;
}

.m-header .menu-mobile .menu-top .mega-menu li.seeallintegrations a:hover {
	//color: #903e23;
}

.m-header .menu-mobile .menu-top .mega-menu .box {
	&:nth-child(1) {
		padding-bottom: 15px;
	}
	&:nth-child(2) {
		padding-top: 25px;
		padding-bottom: 15px;
		border-top: 1px solid #999;
		border-bottom: 1px solid #999;
	}
	&:nth-child(3) {
		padding-top: 25px;
	}
}

// 2023-08-09 + mobil 2023-08-23 hide nl_BE and fr_BE temporarily from menu
// 2023-08-29 -- only fr-fr hidden
// 2023-09-07 -- fr-fr un-hidden
// .m-header .menu-right ul.menu-language > li .sub-menu li#menu-item-347-fr-fr,
// .m-header
// 	.menu-mobile
// 	.menu-bottom
// 	.menu-language
// 	li
// 	.sub-menu
// 	li.lang-item-fr-fr {
// 	display: none;
// }

//language picker 2023-08-20
.m-header .menu-right ul.menu-language > li .sub-menu li {
	margin-bottom: 4px;
}
.lang-item {
	display: flex;
	align-items: center;
	// background: beige;
	padding: 0;
	margin-bottom: 2px;
}

.lang-item a {
	display: flex;
	align-items: center;
	padding: 1px 0;
}
.lang-item a span {
	padding-left: 0.1em;
}
