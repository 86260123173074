.page-404 {

    //container
    .container {
        display: flex;
        align-items: center;
        padding-top: 65px;
        padding-bottom: 65px;


        @include medium {
            flex-direction: column;
            padding-left: 40px;
            padding-top: 35px;
            padding-bottom: 35px;
        }

        @include xsmall {
            padding-left: 20px;
        }
    }

    //col
    .col {
        width: 50%;

        @include medium {
            width: 75%;
        }

        @include xsmall {
            width: 100%;
        }

        &:nth-child(1) {
            padding-right: 30px;

            @include medium {
                margin-bottom: 35px;
                padding-right: 0;
            }
        }

    }

    //content
    .content {
        @include big {
            margin-top: 0;
            margin-bottom: 18px;
        }
    }

    // image
    img {
        display: block;
        margin: 0 auto;
        padding: 0;
        object-fit: cover;
        width: 100%;
        height: auto;

        @include medium {
            display: block;
            margin: 0 auto;
            width: 100%;
        }
    }

    // headline
    h1 {
        margin-bottom: 24px;
    }

    // text
    .tsp {
        color: $black-color;
    }

    // button
    .u-button {
        margin-top: 40px;
    }

}
