.m-features-vertical {

    //shape
    .shape-top {
        position: absolute;
        top: 95px;
        left: -70px;
        opacity: 1;
        z-index: 10;

        @include big {
            display: none;
        }
    }

    .shape-bottom {
        position: absolute;
        bottom: -120px;
        right: -100px;
        opacity: 1;
        z-index: 10;

        @include big {
            display: none;
        }
    }

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // headline
    .headline {
        max-width: 900px;
        margin: 0 auto;
    }


    h3 {
        margin-bottom: 30px;
    }

    // image
    .bg {
        display: block;
        margin: 0 auto;
        padding: 0;
        max-width: 100%;

        @include big {
            display: none;
        }
    }

    // image mobile
    .bg-mobile {
        display: none;
        margin: 30px auto;
        padding: 0;
        max-width: 100%;

        @include big {
            display: block;
        }
    }

    // wrapper
    .wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -20px;
        margin-top: 60px;
        box-sizing: border-box;

        @include big {
            margin-top: 45px;
            justify-content: center;
            flex-direction: column;
        }

        @include small {
            margin: 0;
            margin-top: 45px;
        }

        // col
        .col {
            padding: 0 20px;
            align-self: center;

            &.content-text {
                width: 545px;

                @include big {
                    width: 100%;
                    display: flex;
                    margin: 0 -20px;
                }

                @include small {
                    display: block;
                    margin: 0;
                }
            }

            &:last-child {
                @include big {
                    margin-top: 40px;
                }
            }
        }

        // box
        .box {
            margin-bottom: 60px;

            &:last-child {
                margin-bottom: 0;
            }

            @include big {
                width: 50%;
                padding: 0 20px;
            }

            @include small {
                width: 100%;
                padding: 0;
            }
        }

        // content
        .content {

            // headline
            h6 {
                margin-bottom: 20px;

                @include big {
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            // link
            .u-link {
                margin-top: 20px;
                margin-left: -20px;
            }

        }

    }

    // right
    &.right {

        // wrapper
        .wrapper {
            // col
            .col {
                &.content-text {
                    order: 1;

                    @include big {
                        order: -1;
                    }
                }
            }
        }
    }

}
