table.tempClass {
	border-collapse: collapse;
	border: 2px solid rgb(200, 200, 200);
	font-size: 1em;
	line-height: 1.5;
	width: 100%;
	text-align: left;
	margin: 1.5em 0 1em 0;
	word-wrap: break-word;
}

table.tempClass td,
table.tempClass th {
	border: 1px solid #ececec;
	padding: 10px 20px;
	word-wrap: break-word;
}

table.tempClass th {
	color: white;
	background-color: #2c3551; //comment
}

table.tempClass td {
	// text-align: center;
}

table.tempClass tr:nth-child(even) td {
	background-color: rgb(252, 252, 252);
}

table.tempClass tr:nth-child(odd) td {
	background-color: rgb(250, 250, 250);
}
