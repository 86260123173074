.m-featured-customers {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // headline
    h6 {
        font-family: $real-text;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 34px;
        font-weight: 300;

        @include medium {
            font-size: 15px;
            line-height: 28px;
        }
    }
    
    
    // button
    //2023-04-17
    .buttons {
        padding-top: 33px;
    }

    //content
    .content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1250px;
        margin: 0 auto;
        margin-top: 23px;

        @include big {
            margin-top: 18px;
            justify-content: space-around;
        }

        @include small {
            flex-wrap: wrap;
            justify-content: center;
            max-width: 360px;
        }

        img {
            display: block;
            margin: 0;
            padding: 0;
            //filter: grayscale(1);
            transition: all 300ms ease-in-out;
            margin: 20px;
            max-width: 240px;
            max-height: 50px;

            @include big {
                max-width: 100px;
                margin: 20px 15px;
            }


            @include xsmall {
                margin: 15px 10px;
            }

            &:hover {
                // filter: grayscale(0.1);
                // filter: invert(75%);
                scale: 1.15;
            }
        }
    }

}
