.m-single {
    height: 100%;

    // illustration
    // .shape {
    //     position: absolute;
    //     top: 540px;
    //     left: -100px;
    // }

    // container
    .container {
        display: flex;
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include medium {
            flex-direction: column;
            padding-top: 50px;
            padding-bottom: $section-padding-mobile;
        }

        &.column {
            display: block;

            .col {
                width: 100%!important;
            }
        }

    }

    //col
    .col {
        width: 50%;

        @include medium {
            width: 100%;
        }

        &:nth-child(1) {
            width: 60%;
            padding-right: 80px;

            @include medium {
                width: 100%;
                margin-bottom: 35px;
                padding-right: 0;
            }
        }

        &.wide {
            width: 100%;
            padding-right: 0;
        }

    }

    // col single-content
    .single-content {

        // info
        .info {

            // overline
            .overline-2 {
                display: block;
                margin-bottom: 10px;
            }

            // date
            .tlb {
                display: block;
                margin: 20px 0;
             }

        }

        // featured image
        img {
            max-width: 100%;
            object-fit: cover;
            margin: 20px 0;
        }


        // content
        .content {
            position: relative;

            h1,h2,h3,h4,h5,h6 {
                margin: 20px 0;

                @include big {
                    margin-bottom: 10px;
                }
            }

            a {
                color: $happy-red;

                &:hover {
                    text-decoration: underline;
                }

                &.u-button.default {
                    color: $white-color;
                    &:hover {
                        text-decoration: none;
                    }
                }

                &.u-link.demo {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            p {
                margin-bottom: 20px;
            }

            ol {
                list-style: none;
                counter-reset: gloabl-counter;

                li {
                    position: relative;
                    color: $tp-color;
                    font-size: 18px;
                    line-height: 27px;
                    margin-top: 20px;
                    font-weight: 300;
                    padding-left: 20px;
                    counter-increment: list-counter;

                    &:before {
                        content: counters(list-counter, ".") ".";
                        color: $tp-color;
                        font-size: inherit;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                    @include small {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            ul {
                padding-left: 20px;
                list-style-position: outside;
                list-style-type: disc;
                li {
                    color: $tp-color;
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 300;
                    margin-top: 20px;

                    @include small {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

    }

    // col contact-form
    .contact-form {

        @include medium {
            margin-top: 40px;
        }

        // share box
        .share {
            background-color: $white-color;
            padding: 35px 35px;
            box-shadow: 0 5px 10px 0 rgba(44,53,81,0.12), 0 10px 40px 0 rgba(44,53,81,0.12);
            position: relative;
            margin: 40px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            @include small {
                flex-direction: column;
            }

            // overline
            .overline-2 {
                display: block;

                @include small {
                    text-align: center;
                }
            }

            // social list
            .social-list {
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                margin: 0 20px;

                @include small {
                    margin-top: 10px;
                }

                a {
                    display: block;
                    position: relative;
                    background-color: $grass-green;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 18px;
                    transition: background-color 200ms ease-in-out;

                    @include small {
                        margin: 0 9px;
                    }

                    &:hover {
                        background-color: $grass-green-75;
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
            }

        }

    }


}
