.m-features-horizontal {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    .headline {
        max-width: 900px;
        margin: 0 auto;
    }

    // top
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -20px;
        box-sizing: border-box;

        @include big {
            justify-content: center;
            flex-direction: column;
            margin: 0;
        }

        // col
        .col {
            padding: 0 20px;

            &.content-text {
                width: 580px;

                @include big {
                    width: 100%;
                }
            }

            &:last-child {
                @include big {
                    margin-top: 40px;
                }
            }

        }

        // content
        .content {

            @include big {
                text-align: center;
            }

            // headline
            h3 {
                margin-bottom: 30px;
            }

            // text
            .tlb {

                br {
                    @include big {
                        display: none;
                    }
                }
            }

        }

        // image
        .bg {
            display: block;
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }

    }

    // bottom
    .bottom {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -40px;
        box-sizing: border-box;

        @include big {
            margin: 0;
        }

        // box
        .box {
            position: relative;
            margin-top: 73px;
            padding: 0 40px;
            width: 33.333%;
            box-sizing: border-box;

            @include big {
                width: 50%;
                padding: 0 20px;
                margin-top: 60px;
            }

            @include small {
                width: 100%;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 40px;
                width: 40px;
                height: 5px;
                background: $grass-green;

                @include big {
                    left: 20px;
                }
            }

            // content
            .content {
                position: relative;
                padding-right: 10px;
                z-index: 10;

                // headline
                h6 {
                    margin-top: 25px;
                    margin-bottom: 18px;

                    @include big {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }

                // link
                .u-link {
                    margin-top: 23px;
                    margin-left: -20px;
                }
            }
        }


    }

    // right
    &.right {

        // top
        .top {
            // col
            .col {
                &.content-text {
                    order: 1;

                    @include big {
                        order: -1;
                    }
                }
            }
        }
    }

    // center
    &.center {

        // top
        .top {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;

            // col
            .col {

                &.content-text {
                    width: 100%;
                }

                &:first-child {
                    margin-bottom: 40px;
                }

            }

            // content
            .content {
                text-align: center;

                // text
                .tlb {

                    br {
                        display: none;
                    }
                }

            }


        }

    }
}
