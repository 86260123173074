.hbspt-form {
    display: inline-block;
    position: relative;
    background-color: $white-color;
    padding: 65px;
    box-shadow: 0 5px 10px 0 rgba(44,53,81,0.12), 0 10px 40px 0 rgba(44,53,81,0.12);

    @include big {
        padding: 70px 45px;
    }

    label {
        line-height: 24px;
        color: $black-color;
        font-weight: 300;
    }

    button,
    input:not([type="submit"]),
    select,
    textarea {
        margin: 0;
        padding: 8px 10px;
        border: none;
        border-radius: 3px;
        background-color: $black-5;
        line-height: inherit;
        font: inherit;
        color: inherit;
        transition: all 200ms ease-in-out;
        font-weight: 300;
        font-size: 14px;
        color: $tp-color;
    }

    input:not([type="submit"]) {
        width: 100%;
        padding: 8px 10px;
        color: $tp-color;
        font-weight: 300;
        font-size: 14px;
    }

    input:not([type="submit"]),
    input:not([type="checkbox"]),
    select {
        &:focus {
            box-shadow: inset 0 0 0 1px $grass-green;
        }
    }

    input.error {
        box-shadow: inset 0 0 0 1px $happy-red;
    }

    input[type="checkbox"] {
        position: relative;
        top: 4px;
        padding: 0;
        width: 17px;
        height: 17px;
        appearance: none;
        outline: none;
        box-shadow: none !important;
        background-color: transparent !important;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid $black-color;
            background-color: $white-color;
            box-sizing: border-box;
            border-radius: 50%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 17px;
            height: 17px;
            border: 2px solid $grass-green;
            background-color: transparent;
            box-sizing: border-box;
            border-radius: 50%;
            display: none;
        }
    }

    input:checked[type="checkbox"]:before {
        background-color: $grass-green;
        width: 13px;
        height: 13px;
        top: 2px;
        left: 2px;
        border: 2px solid $white-color;
    }

    input[type="checkbox"]:focus,
    input[type="checkbox"]:before:focus,
    input[type="checkbox"]:after:focus {
       outline:0!important;
       box-shadow: none!important;
    }

    input:checked[type="checkbox"]:after {
        display: block;
    }

    // submit
    .hs_submit.hs-submit {
        margin-top: 35px;
        text-align: center;

        .actions {
            text-align: center;
            position: relative;
            display: inline-block;
            border-radius: 19px;
            transition: all 200ms ease-in-out;
            color: $white-color !important;
            background-color: $happy-red !important;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 50px;
                width: 38px;
                height: 100%;
                background: $happy-red-hover;
                transition: all 400ms ease;
                @include small {
                    width: 33px;
                }
            }

            &:hover:before {
                width: 100%;
            }

            input[type="submit"] {
                position: relative;
                display: block;
                font-size: 14px;
                font-weight: 600;
                line-height: 1;
                text-transform: uppercase;
                letter-spacing: 0.6px;
                text-align: center;
                white-space: nowrap;
                padding: 13px 52px 10px 23px;
                z-index: 10;
                box-shadow: none;
            }
        }
    }

    .legal-consent-container {
        margin-top: 40px;

        label.hs-form-booleancheckbox-display {

             > span {
                display: inline-block;
                margin-left: 40px;
                margin-top: -25px;

                > p {
                    display: inline;
                    color: $black-color;

                    a {
                        color: $happy-red;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                span.hs-form-required {
                    display: inline;
                }
            }


        }
    }

    // select
    select {
        position: relative;
        width: 100%;
        outline: none;
        appearance: none;
        outline: none;
        background-image: url("../img/arrow-dropdown.svg");
        background-repeat: no-repeat;
        background-position: right 25px center;
        padding: 11px 45px 10px 10px;
        color: $tp-color;

        &.hs-input.invalid.error {
            box-shadow: inset 0 0 0 1px $happy-red;
        }

        option {
            border: none;
            font-weight: 300;
        }
    }


    // default field
    .hs-form-field {
        position: relative;
        margin-bottom: 15px;

        &:after {
            content: '';
            visibility: hidden;
            display: block;
            height: 0;
            clear: both;
        }

        @include medium {
            margin-bottom: 20px;
        }

        .hs-error-msg {
            position: absolute;
            bottom: 40px;
            right: 0;
            color: $happy-red;
            font-size: 11px;
            line-height: 1.2;
            font-weight: 300;
            max-width: 200px;
            text-align: right;

            @include small {
                position: relative;
                display: block;
                bottom: 0;
                text-align: left;
                margin-top: 5px;
                max-width: 100%;
            }
        }

    }

    // textarea
    textarea {
        padding: 8px 10px;
        width: 100%;
        min-height: 110px;
        resize: vertical;
        line-height: 1.5;
        color: $tp-color;

        &:focus {
            box-shadow: inset 0 0 0 1px $grass-green;
        }

        &.error {
            box-shadow: inset 0 0 0 1px $happy-red;
        }

    }

    // textarea errors
    .hs-fieldtype-textarea.hs-form-field {
        margin-top: 20px;

        .hs-error-msg {
            top: 7px;
            right: 0;
            bottom: unset;

            @include small {
                margin-top: 0;
            }
        }
    }

    .hs_error_rollup {
        margin-top: 30px;
        text-align: center;

        label {
            color: $happy-red;
        }
    }

    // policy text
    .legal-consent-container {
        .hs-form-field {
            .hs-error-msg {
                position: absolute;
                top: -15px;
                bottom: unset;
                right: 0;
                color: $happy-red;
                max-width: 100%;

                @include small {
                    position: relative;
                    display: block;
                    padding-left: 40px;
                    top: 0;
                    bottom: 0;
                    margin-top: 5px;
                }
            }
        }
    }

    // re-captcha
    .hs_recaptcha.hs-recaptcha.field.hs-form-field {
        display: none!important;
    }


    // phone field
    .hs-phone.hs-form-field {
        .hs-error-msg {
            top: 7px;
            right: 0;
            bottom: unset;

            @include small {
                position: relative;
                display: block;
                top: -15px;
                bottom: unset;
                text-align: left;
                margin-top: 0;
                max-width: 100%;
            }
        }
    }


    // phone select
    .hs-fieldtype-intl-phone.hs-input select {
        float: left;
        width: 40% !important;

        @include small {
            float: none!important;
            display: block;
            margin-bottom: 15px;
            width: 100%!important;
        }

    }

    // phone input
    .hs-fieldtype-intl-phone.hs-input input {
        width: 58% !important;
        float: right;

        @include small {
            float: none!important;
            position: relative;
            display: block;
            margin-bottom: 20px;
            width: 100%!important;
        }

    }

}
