.u-article {
	flex: 1;
	position: relative;
	padding-left: 60px;

	&.wide {
		padding-left: 0;
	}

	@include big {
		padding-left: 0;
	}

	> *:first-child {
		margin-top: 0 !important;
	}

	> *:last-child {
		margin-bottom: 0 !important;
	}

	.content {
		> *:first-child {
			margin-top: 0 !important;
		}

		> *:last-child {
			margin-bottom: 0 !important;
		}
	}

	// image
	img {
		display: block;
		margin: 0;
		padding: 0;
		max-width: 100%;
		height: auto;
		object-fit: cover;
	}

	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid $ink-spot-25;
		margin: 20px 0;
		padding: 0;
	}

	ul li,
	ol li {
		color: $tp-color;
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;

		&:not(:first-child) {
			margin-top: 5px;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 40px;
		margin-bottom: 20px;

		@include big {
			margin-bottom: 10px;
		}
	}

	a {
		//word-break commented out 2023-0628 -- no idea why someone but this in place here...
		//word-break: break-all;
		color: $happy-red;
		&:hover {
			text-decoration: underline;
		}

		&.u-button.default {
			color: $white-color;
			&:hover {
				text-decoration: none;
			}
		}

		&.u-link.demo {
			&:hover {
				text-decoration: none;
			}
		}
	}

	p {
		margin-bottom: 20px;
	}

	ol {
		margin-bottom: 20px;
		list-style: none;
		counter-reset: list-counter;

		li {
			position: relative;
			color: $tp-color;
			font-weight: 300;
			padding-left: 20px;
			counter-increment: list-counter;

			&:before {
				content: counters(list-counter, ".") ".";
				color: $tp-color;
				font-size: inherit;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	ul {
		margin-bottom: 20px;
		padding-left: 15px;
		list-style-position: outside;
		list-style-type: disc;

		li {
			color: $tp-color;
			font-weight: 300;
		}
	}
}
