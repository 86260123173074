.u-stats {
    position: relative;
    width: 25%;
    text-align: center;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 0 20px;

    @include big {
        margin: 30px 0;
        width: 30%;
    }

    @include medium {
        width: 40%;
    }

    @include small {
        width: 100%;
        padding: 0;
    }

    &.wide {
        margin: 20px;
        padding: 0;
        width: calc(33.33% - 40px);

        @include medium {
            width: calc(50% - 40px);
            margin: 30px 10px;
        }

        @include small {
            width: 100%;
            padding: 0;
            margin: 30px 0;
        }

    }


    &.highlighted-description {

        // box
        .box {
            border: 3px solid $ink-spot-25;
        }

        // content
        .content {

            .tlb {
                color: $grass-green;
                font-weight: 600;
            }
        }
    }

    // box
    .box {
        position: relative;
        border: 3px solid $grass-green;
        border-radius: 10px;
        height: 100%;
    }



    // data-healdine
    .data-headline {
        position: absolute;
        width: 100%;
        left: 0;
        top: -35px;
        text-align: center;
        z-index: 10;

        // data
        .data {
            display: inline-block;
            background-color: $white-color;
            padding: 0 20px;

            // value
            .value {
                //2023-04-17
                color: #000;
                font-size: 60px;
                font-family: $real-headline;
                vertical-align: middle;
            }

            // value-additional
            .value-additional {
                font-size: 60px;
                color: #000;
                font-family: $real-headline;
                vertical-align: middle;

                &.prefix {
                    font-size: 40px;
                }
            }

        }

    }

    // content
    .content {
        padding: 40px 20px 20px 20px;
    }

    &.content-white {

        // data
        .data {
            color: $white-color!important;

            // value
            .value {
                color: $white-color!important;
            }

            // value-additional
            .value-additional {
                color: $white-color!important;
            }

        }

        // box
        .box {
            border: 3px solid $grass-green!important;
        }

        // content
        .content {

            .tlb {
                color: $white-color!important;
            }
        }
    }

}
