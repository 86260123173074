.m-download-app {
    margin-top: 100px;

    // container
    .container {
        padding-bottom: $section-padding;

        @include big {
            padding-bottom: $section-padding-mobile;
        }
    }

    // illustration
    .shape {
        position: absolute;
        bottom: 50px;
        left: 0;
        z-index: -10;

        @include big {
            display: none;
        }
    }

    // image
    .bg {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        margin-top: -150px;
        object-fit: cover;

        @include big{
            margin-top: -120px;
        }
    }

    // container-full
    .container-full {
        padding-bottom: $section-padding;

        @include big {
            padding-bottom: $section-padding-mobile;
        }
    }

    // download-wrapper
    .download-wrapper {
        position: relative;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1140px;
        top: -100px;
        box-sizing: border-box;

        @include medium {
            flex-direction: column;
        }
    }

    // download-card
    .download-card {
        position: relative;
        width: 50%;
        padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 40px;

        @include medium {
            width: 100%;
        }

        // content
        .content {
            padding: 65px 45px 35px;
            background-color: $white-color;
            box-shadow: 0 5px 10px 0 rgba(44,53,81,0.12), 0 10px 40px 0 rgba(44,53,81,0.12);
        }

        // headline
        h6 {
            display: block;
            position: relative;
            margin-bottom: 20px;
            padding-top: 30px;

            &:before {
                content: '';
                position: absolute;
                top: -20px;
                left: 0;
                background-repeat: no-repeat;
                width: 33px;
                height: 33px;
            }
        }

        // apps
        .get-apps {
            position: relative;
            margin-top: 20px;
            margin-left: -20px;
            margin-right: -20px;
            display: flex;

            @include medium {
                justify-content: center;
            }

            @include xsmall {
                flex-direction: column;
                align-items: center;
            }

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 10px 20px;
                background-color: $black-color;
                border-radius: 3px;
                width: 158px;
                height: 48px;

                a {


                    img {
                        display: block;
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                    }
                }

            }

        }

        &.desktop {
            // headline
            h6 {
                &:before {
                    background-image: url('../img/app-desktop.svg');
                }
            }
        }
        &.mobile {
            // headline
            h6 {
                &:before {
                    background-image: url('../img/app-mobile.svg');
                }
            }
        }

    }

}
