.m-timeline {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }


    // headline
    .headline {
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 65px;
    }

    // text
    .tsp {
        margin-top: 20px;
    }

    .wrapper {
        position: relative;
        margin: 0 auto;
        margin-bottom: -30px;
        max-width: 1000px;
    }

    // timeline-box
    .timeline-box {
        position: relative;
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100%;
        cursor: default;
        display: none;

        &.is-hidden {
            display: none;
        }

        &.is-visible {
            display: block!important;
        }

        // content
        .content {
            display: flex;
            flex: 1;
            position: relative;
            height: 100%;
            min-height: 29px;
        }

        // year
        .year {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 60px;
            text-align: right;

            @include small {
                top: 0;
                left: 0;
                transform: none;
                text-align: left;
                width: 50px;
            }
        }

        .content-icon {
            position: absolute;
            top: 10px;
            left: 95px;

            @include small {
                top: 10px;
                left: 65px;
                transform: none;
            }

            // icon
            .icon {
                display: block;
                width: 29px;
                height: 29px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all 200ms ease-in-out;
            }

        }


        // description
        .description {
            position: relative;
            padding-left: 135px;
            transition: all 200ms ease-in-out;
            width: 100%;

            @include small {
                padding-left: 95px;
            }
        }

        &.is-active {

            @include small {
                min-height: 29px;
                margin: 0 auto;
                margin-bottom: 20px;
            }

            .description {
                color: $grass-green;
            }

            .content-icon {

                // icon
               .icon {
                   width: 40px;
                   height: 40px;

                   @include small {
                       width: 29px;
                       height: 29px;
                   }
               }

            }


        }

    }

    // load more
    .load-more {
        text-align: center;
        margin: 0 auto;
        margin-top: 25px;
        padding: 0;

        a {
            display: inline-block;
            position: relative;
            text-align: center;
            transition: color 200ms ease-in-out;
            font-weight: 600;

            &:hover {
                color: $grass-green;
            }

            span {
                display: block;
                margin-bottom: 5px;
            }

            img {
                margin: 0 auto;
                padding: 0;
                transition: transform 200ms ease-in-out;

                &.is-hidden {
                    transform: rotate(-180deg);
                }
            }
        }
    }

}
