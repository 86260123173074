#FQSquared {
   background: aliceblue;
   
    //container
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 136px;
        padding-bottom: 136px;
    
        @include big {
            padding-top: 65px;
            padding-bottom: 65px;
        }
    
        @include medium {
            // justify-content: flex-end;
            max-width: 100%;
            padding: 0;
            padding-top: 65px;
            padding-bottom: 65px;
        }
        
        .content {
            display: flex;
            flex-direction: row;
            
            @include medium {
               margin: 0 20px;
            }
            
            @include small {
               width: auto;
               margin: 0 20px;
               flex-direction: column
            }
        }
        
        .content .imageDiv img {
           position: relative;
           height:100%;
           @include small {
              height:auto;
              width:100%;
           }
        }
        
        
        
        
        .content .textDiv {
            //background: #f9f9f9;
            margin: 3em 3.5em; 
            max-width: 490px;
            
            @include big {
                max-width: 350px;
            }
            
            @include medium {
               // right: 80px;
            }
            
            @include small {
                max-width: 320px;
                padding-bottom: 40px;
            }
            
            
            .allcaps {
               font-size: 13px;
               letter-spacing: 2px;
               text-transform: uppercase;
               color: #ccc;
            }
            
            .like-h4 {
               
               // TODO: Make dynamic color
               color:#fff;
               margin-top: 1.5em;
               
               position: relative;
               font-size: 30px;
               line-height: 36px;
               
               &.medium {
                  font-size: 2px;
               }
               
               
               // &.small {
               //      font-size: 20px;
               //      line-height: 28px;
               // 
               //      span {
               //          &:first-child {
               //              margin-left: -16px;
               //          }
               //      }
               // 
               //      @include big {
               //          font-size: 22px;
               //          line-height: 30px;
               // 
               //          span {
               //              &:first-child {
               //                  margin-left: -14px;
               //              }
               //          }
               //      }
               //  }
                
                @include big {
                     font-size: 22px;
                     line-height: 30px;
                 }
                
                 span {
                     color: $grass-green;
                
                     &:first-child {
                         margin-left: -25px;
                
                         @include big {
                             margin-left: -14px;
                         }
                     }
                 }
            }
            
            .overline-2 {
                color: #fff;
                margin-top: 27px;
            
                @include big {
                    margin-top: 13px;
                    max-width: 325px;
                }
            
                @include xsmall {
                    margin-top: 27px;
                }
            }
            
            .u-button {
               margin-top: 36px;
            }
            
        }
        
    }
    
}