.m-services {
	// container
	.container {
		padding-top: $section-padding;
		padding-bottom: $section-padding;

		@include big {
			padding-top: $section-padding-mobile;
			padding-bottom: 0;
			max-width: 960px;
		}
	}

	//shape
	.shape {
		position: absolute;
		top: -320px;
		right: -100px;

		@include big {
			display: none;
		}

		#rotatingShape {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	// wrapper
	.wrapper {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-top: 105px;
		z-index: 10;

		@include big {
			flex-wrap: wrap;
			justify-content: space-around;
			margin-top: 92px;
		}

		@include small {
			margin-top: 86px;
		}

		// box
		.box {
			position: relative;
			width: 25%;
			max-width: 277px;
			border: 3px solid $grass-green;
			border-radius: 10px;
			text-align: center;
			box-sizing: border-box;
			margin: 0 10px;
			padding-bottom: 81px;

			@include big {
				width: 50%;
				margin-bottom: 73px;
			}

			@include small {
				width: 100%;
				margin-bottom: 100px;
			}

			@include xsmall {
				&:last-child {
					margin-bottom: 65px;
				}
			}

			&:before {
				content: "";
				position: absolute;
				top: -49px;
				left: 50%;
				transform: translateX(-50%);
				width: 99px;
				height: 98px;
				border-radius: 50%;
				background-color: $ink-spot-8;
			}

			// icon
			.icon {
				position: absolute;
				top: -60px;
				left: 50%;
				transform: translateX(-50%);
			}

			// headline
			h6 {
				margin-top: 65px;
				margin-bottom: 20px;

				@include big {
					font-size: 20px;
					line-height: 28px;
				}

				@include small {
					margin-bottom: 15px;
				}
			}

			// content
			.content {
				padding: 0 37px 10px 37px;
				min-height: 175px;

				// text
				p {
					color: $tp-color;
					line-height: 24px;

					strong {
						font-weight: 400;
						background-image: linear-gradient(
							to right,
							$grass-green-25 0,
							$grass-green-25 100%
						);
						background-size: 0 90%;
						background-repeat: no-repeat;
						transition: background 450ms ease;
					}
				}
			}

			.buttons {
				// background: red;
				position: absolute;
				left: 50%;
				bottom: 36px;
				text-align: center;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				transform: translateX(-50%);
			}

			// button
			.u-button {
				// margin-bottom: 38px;
			}

			&:hover .content p strong {
				background-size: 100% 90%;
			}
		}
	}
}
