.m-guests {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }
    
    // wrapper
    .wrapper {
        display: flex;
        flex-direction: column;

        // box
        .box {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            margin: 0;
            margin-top: 40px;
            padding: 35px;
            border: 3px solid $ink-spot-25;
            border-radius: 10px;
            box-sizing: border-box;

            @include medium {
                flex-direction: column;
                align-items: flex-start;
            }

            // image
            img.person {
                display: block;
                margin: 0;
                padding: 0;
                object-fit: cover;
                max-width: 100%;

                @include small {
                    max-width: 155px;
                    width: 100%;
                }
            }

            // content
            .content {
                display: block;
                margin: 0;
                padding: 0 40px;
                flex: 1;

                @include medium {
                    margin-top: 20px;
                    padding: 0;
                }

                // headline
                h4 {
                    margin-bottom: 10px;
                }

                // overline
                .overline-2 {
                    display: inline-block;
                    margin-bottom: 20px;

                    @include medium {
                        margin-bottom: 10px;
                    }
                }

                // social list
                .social-list {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    margin: 0 -10px;
                    margin-top: 20px;

                    @include medium {
                        margin: 0 -10px;
                        margin-top: 20px;
                    }

                    a {
                        display: block;
                        position: relative;
                        background-color: $grass-green;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        margin: 0 10px;
                        transition: background-color 200ms ease-in-out;

                        @include medium {
                            margin: 0 10px;
                        }

                        &:hover {
                            background-color: $grass-green-75;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                    }
                }

            }
        }
    }

}
