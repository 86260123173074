.m-text {
    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // content
    .content {
        margin: 0 auto;
        max-width: 1050px;

        > *:not(:last-child) {
            margin-bottom: 25px;
        }
    }
}
