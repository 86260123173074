.m-feature {

    // container
    .container {
        position: relative;
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    &.small_padding_top .container {
        padding-top: $section-padding-mobile;

        @include big {
            padding-top: $section-padding-mobile;
        }
    }

    &.small_padding_bottom .container {
        padding-bottom: $section-padding-mobile;

        @include big {
            padding-bottom: $section-padding-mobile;
        }
    }

    &.has_separator .container:before {
        position: absolute;
        top: 0;
        left: 15px;
        width: calc(100% - 30px);
        height: 1px;
        background-color: $ink-spot-25;
        content: '';

        @include small {
            left: 20px;
            width: calc(100% - 40px);
        }
    }

    //shape
    .shape-top {
        position: absolute;
        top: -130px;
        right: 0px;
        opacity: 1;

        @include big {
            display: none;
        }
    }

    //shape
    .shape-bottom-left{
        position: absolute;
        bottom: -35px;
        left: -160px;
        opacity: 1;
        z-index: 1;

        @include big {
            display: none;
        }
    }

    //shape
    .shape-bottom-right {
        position: absolute;
        bottom: 80px;
        right: -130px;
        opacity: 1;
        z-index: 10;

        @include big {
            display: none;
        }
    }


    // wrapper
    .wrapper {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        margin: 0 -20px;
        box-sizing: border-box;

        @include big {
            justify-content: center;
            flex-direction: column;
            margin: 0;
        }

        // col
        .col {
            padding: 0 20px;

            &.content-text {
                width: 547px;

                @include big {
                    margin-top: 0;
                    width: 100%;
                }
            }

            @include big {
                width: 100%;
                text-align: center;
            }

            @include small {
                text-align: left;
            }

            &:last-child {
                @include big {
                    margin-top: 40px;
                }

                &:not(.content-text) {
                    @include big {
                        display: none;
                    }
                }
            }

        }


        // headline
        h4 {
            margin-bottom: 30px;
        }

        // button
        .u-button {
            margin-top: 65px;

            @include big {
                margin-top: 45px;
            }
        }

        // image
        .bg {
            display: block;
            margin: 0 auto;
            padding: 0;
            max-width: 100%;
        }

        // image mobile
        .bg-mobile {
            display: none;
            margin: 30px auto;
            padding: 0;
            max-width: 100%;

            @include big {
                display: block;
            }
        }

    }


    &.right {

        // wrapper
        .wrapper {
            // col
            .col {
                &.content-text {
                    order: 1;

                    @include big {
                        order: -1;
                    }
                }
            }
        }
    }

}
