.m-contact-banner {
    background-color: $ink-spot;
    color: $white-color;
    padding-top: $section-padding-mobile;
    padding-bottom: $section-padding-mobile;


    // container
    .container {
        display: flex;

        @include big {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }

    // col
    .col {
        width: 50%;
        align-self: center;

        @include big {
            width: 100%;
        }

        &:last-child {
            @include big {
                margin-top: 10px;
            }
        }
    }

    // headline
    h3 {
        margin-bottom: 10px;
        color: $white-color;
    }

    // text
    .tlb {
        color: $white-color;
    }

    // buttons
    .buttons {
        display: flex;
        justify-content: flex-end;
        align-self: center;
        margin-top: 10px;

        @include big {
            justify-content: center;
            padding-right: 0;
        }

        @include small {
            display: block;
        }


        .u-button {
            margin: 0 15px;

            @include small {
                margin: 5px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

    }


}
