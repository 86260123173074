.m-featured-customer-story {

    //container
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 136px;
        padding-bottom: 136px;

        @include big {
            padding-top: 65px;
            padding-bottom: 65px;
        }

        @include medium {
            justify-content: flex-end;
            max-width: 100%;
            padding: 0;
            padding-top: 65px;
            padding-bottom: 65px;
        }
        
    }

    .title_section {
        display: block;
        width: 100%;
        margin-bottom: 40px;
    }

    // wrapper
    .wrapper {
        display: flex;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 1000px;

        @include small {
            max-width: none;
        }

        // content
        .content {
            position: absolute;
            top: 120px;
            right: 0;
            width: 530px;

            @include big {
                width: 440px;
                right: 20px;
            }

            @include medium {
                right: unset;
                left: 380px;
                width: 290px;
            }

            @include small {
                top: 380px;
                left: 50%;
                transform: translateX(-50%);
                width: 290px;

            }

            // overline
            .overline-2 {
                display: block;
                margin-bottom: 20px;
            }

            // description
            .tlb {
                margin-top: 30px;
            }
        }
        
        // img
        .animated-image-parallax {
            position: absolute;
            top: 75px;
            left: 0;
            z-index: 10;
            max-width: 404px;

            @include medium {
                top: 145px;
                left: 20px;
                max-width: 290px;
            }

            @include small {
                top: 75px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 100%;
            }

            img {
                display: block;
                width: 100%;
                object-fit: cover;
            }
        }

        // logotype
        .customer-logo {
            position: absolute;
            top: 20px;
            right: 20px;
            max-width: 150px;
            z-index: 20;

            @include small {
                top: 0;
            }
        }

        // background wrapper
        .wrapper-bg {
            position: relative;
            padding-left: 60px;

            @include medium {
                overflow-x: hidden;
            }

            @include small {
                width: 100%;
                margin-top: 200px;
                padding-left: 0;
            }

            .blob {
                width: 834px;
                height: 567px;
                margin: 0;
                padding: 0;
                display: block;

                @include small {
                    width: auto;
                    // height: 800px;
                    margin-left: -100px;
                }

            }

        }
    }

}
