.u-modal-overlay {
    z-index: 9999;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(250,250,250,.85);

    .modal-close-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    // wrapper
    .modal-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 40px;

        @include medium {
            padding: 20px;
        }
    }

    // modal-box
    .modal-box {
        position: relative;
        margin: 0 auto;
        max-width: 1270px;
        width: 100%;
        overflow: auto;
        max-height: calc(100vh - 40px);


        // box
        .box {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            // height: 100%;
            height: min-content;
            margin: 0;
            padding: 35px;
            background-color: $ink-spot;
            box-sizing: border-box;

            @include medium {
                flex-direction: column;
                align-items: flex-start;
            }

            // close
            .modal-close {
                position: absolute;
                top: 15px;
                right: 15px;
                width: 19px;
                height: 19px;
                z-index: 10;
                cursor: pointer;
            }

            // image
            img.person {
                display: block;
                margin: 0;
                padding: 0;
                object-fit: cover;
                max-width: 100%;
                height: auto;

                @include medium {
                    max-width: 155px;
                    width: 100%;
                }
            }

            // content
            .content {
                display: block;
                margin: 0;
                padding: 0 40px;
                flex: 1;
                color: $white-color;

                @include medium {
                    margin-top: 20px;
                    padding: 0;
                }

                // headline
                h4 {
                    margin-bottom: 10px;
                    color: $white-color;
                }

                // text
                p {
                    color: $white-color;
                }

                // overline
                .overline-2 {
                    display: inline-block;
                    margin-bottom: 20px;
                    color: $white-color;

                    @include medium {
                        margin-bottom: 10px;
                    }
                }

                // headshot
                .headshot {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 20px;

                    @include small {
                        display: block;
                    }
                }

                // download-headshot
                .download-headshot {
                    position: relative;
                    display: inline-block;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $grass-green;
                    }

                    @include small {
                        margin-top: 20px;
                    }

                    img {
                        display: inline-block;
                        margin-left: 5px;
                        vertical-align: sub;
                    }
                }

                // social list
                .social-list {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    margin: 0 -10px;

                    @include medium {
                        margin: 0 -10px;
                    }

                    a {
                        display: block;
                        position: relative;
                        background-color: $grass-green;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        margin: 0 10px;
                        transition: background-color 200ms ease-in-out;

                        @include medium {
                            margin: 0 10px;
                        }

                        &:hover {
                            background-color: $grass-green-75;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                    }
                }

            }

            // headline
            .content-info {
                position: relative;
                display: flex;
                align-self: center;
                margin-bottom: 20px;
                padding: 0 40px;
                color: $white-color;

                @include medium {
                    margin-top: 20px;
                    padding: 0;
                    flex-direction: column;
                }

                // flag
                .flag {
                    padding-right: 20px;

                    img {
                        display: block;
                        margin: 0;
                        padding: 0;
                        object-fit: cover;
                        width: 60px;
                        height: auto;
                    }
                }

                // info
                .info {

                    @include medium {
                        margin-top: 20px;
                    }

                    .title {
                        position: relative;
                        margin-bottom: 0;
                        color: $white-color;
                        margin-bottom: 20px;
                    }

                    .description {
                        display: block;
                        position: relative;
                        margin-bottom: 20px;
                        color: $white-color;
                        padding-left: 40px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-image: url('../img/tel-icon-information.svg');
                            background-repeat: no-repeat;
                            width: 22px;
                            height: 24px;
                        }
                    }

                    .price {
                        display: block;
                        position: relative;
                        color: $white-color;
                        padding-left: 40px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-image: url('../img/tel-icon-price.svg');
                            background-repeat: no-repeat;
                            width: 11px;
                            height: 24px;
                        }
                    }

                    .instruction {
                        margin-top: 40px;

                        h5 {
                            color: $white-color;
                            margin-bottom: 20px;

                            @include big {
                                font-size: 22px;
                                line-height: 30px;
                            }

                        }

                        .phone {
                            display: block;
                            position: relative;
                            color: $white-color;
                            margin-bottom: 20px;
                            padding-left: 40px;

                            &:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-image: url('../img/tel-icon-phone.svg');
                                background-repeat: no-repeat;
                                width: 20px;
                                height: 20px;
                            }

                            span {
                                color: $happy-red;
                                font-weight: 600;
                            }
                        }

                        .code {
                            display: block;
                            position: relative;
                            color: $white-color;
                            margin-bottom: 20px;
                            padding-left: 40px;

                            &:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-image: url('../img/tel-icon-lock.svg');
                                background-repeat: no-repeat;
                                width: 18px;
                                height: 22px;
                            }
                        }

                        .mobile {
                            display: block;
                            position: relative;
                            color: $white-color;
                            margin-bottom: 20px;
                            padding-left: 40px;

                            &:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-image: url('../img/tel-icon-mobile.svg');
                                background-repeat: no-repeat;
                                width: 15px;
                                height: 24px;
                            }

                            span {
                                color: $happy-red;
                                font-weight: 600;
                            }
                        }
                    }

                }

            }

        }

    }

}
