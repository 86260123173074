.m-intro {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // content
    .content {
        margin: 0 auto;
        max-width: 980px;
    }

    // headline
    h6 {
        font-family: $real-text;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 34px;
        font-weight: 300;
        margin-bottom: 20px;

        @include medium {
            font-size: 15px;
            line-height: 28px;
        }
    }

}
