* {
	scroll-margin-top: 85px;
}


html {
	scroll-behavior: smooth;
	font-display: swap;
	font-family: sans-serif;
}

body {
	font-family: $real-text, ui-sans-serif, sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: $tp-color;
	overflow-x: hidden;
	font-variant-numeric: lining-nums;
	padding-top: 85px;

	@include big {
		padding-top: 60px;
	}
}

#wpadminbar {
	position: fixed !important;
}

/* container */
.container {
	position: relative;
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1300px;
	width: 100%;
	height: 100%;

	@include big {
		padding: 0 40px;
	}

	@include small {
		padding: 0 20px;
	}
}

.container-full {
	position: relative;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	max-width: 100%;
	padding: 0;
}

.disable_padding_top .container {
	padding-top: 0px !important;
}

.disable_padding_bottom .container {
	padding-bottom: 0px !important;
}

/* section */
section {
	position: relative;
}

/* col */
.col {
	position: relative;
	box-sizing: border-box;
}

/* backround colors */
.bg-light-green {
	background-color: $grass-green-8;
}

.bg-light-grey {
	background-color: $ink-spot-8;
}
