// fade in
.animation-fade-in:not(.animation-sequence-parent),
.animation-fade-in.animation-sequence-parent > *:not(br) {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s, transform 1s;
}

.animation-fade-in.animation-subtle:not(.animation-sequence-parent),
.animation-fade-in.animation-subtle.animation-sequence-parent > *:not(br) {
    transform: translateY(25px);
}

.is-animated.animation-fade-in,
.is-animated:not(.animation-sequence-parent) .animation-fade-in,
.is-animated .animation-sequence-parent.animation-fade-in > *:not(br) {
    opacity: 1 !important;
    transform: translateY(0) !important;
}

// wipe in left
.animation-wipe-in-left {
    clip-path: inset(0 0 0 100%);
    overflow: hidden;
    transition: clip-path 2s;
}

.is-animated.animation-wipe-in-left,
.is-animated .animation-wipe-in-left {
    clip-path: inset(0) !important;
}
