.m-hero {
	// re-uploaded 2024-01-29
	.container {
		display: flex;
		align-items: center;
		padding-top: 85px;
		padding-bottom: 150px;

		@include big {
			padding-top: $section-padding-mobile;
			padding-bottom: 120px;
		}

		@include medium {
			flex-direction: column;
			padding-top: 35px;
		}
	}

	//col
	.col {
		width: 50%;

		@include medium {
			width: 75%;
		}

		@include xsmall {
			width: 100%;
		}

		&:nth-child(1) {
			// max-width: 590px;
			padding-right: 20px;

			@include medium {
				max-width: 100%;
				margin-bottom: 35px;
				padding-right: 0;
			}
		}
	}

	// hero-illustration
	img.hero-illustration {
		display: block;
		margin: 0;
		padding: 0;
		object-fit: cover;
		width: 100%;

		@include medium {
			display: block;
			margin: 0 auto;
			//2023-09-25 here instead of inline by Gustav:
			//width: 100%;
		}
	}

	// background element
	img.shape-u {
		position: absolute;
		top: 0;
		left: 105px;

		&.loaded {
			@include big {
				height: 220px;
				left: 45px;
				top: -40px;
			}
		}

		@include medium {
			left: -90px;
		}
	}

	//content
	.content {
		padding-right: 40px;

		@include big {
			padding-right: 0;
		}
	}

	//headline
	.huge-text {
		margin-top: 21px;
		margin-bottom: 30px;

		@include big {
			margin-top: 0;
			margin-bottom: 18px;
		}
	}

	// text
	.tsp {
		@include big {
			max-width: 90%;
		}
	}

	// buttons
	.buttons {
		white-space: nowrap;
		margin-top: 65px;
		margin-bottom: 10px;

		@include big {
			margin-top: 35px;
		}

		@include small {
			white-space: normal;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.u-button {
			margin-right: 20px;

			@include small {
				margin-right: 0;
			}
		}

		.u-link {
			@include small {
				margin-top: 20px;
				// margin-left: -18px;
			}
		}
	}
}

// hero + announcement
// added z-index 2024-05-06 to prevent bottom shadow being cut off
// changed from 123 to 1 otherwise obscuring the nav
.m-hero + .m-announcement {
	z-index: 1;
	margin-top: -40px;

	@include medium {
		margin-top: -105px;
	}
}

// hero Well Connected
#WellConnected {
	//border: 5px solid red;

	.huge-text {
		//margin-top: 210px;
		color: #fff;
	}

	// text
	.tsp {
		color: #eee;
	}
}

#Forrester {
	//border: 5px solid red;

	.container {
		padding-top: 10px;
		padding-bottom: 10px;

		.imgcol {
			//display: flex;
			//padding-left: 120px;

			@include small {
				padding-left: 0;
				// margin-left: -18px;
			}
			@include medium {
				padding-left: 0;
				// margin-left: -18px;
			}
		}

		.hero-illustration {
			display: block;
			margin: 0 auto;
			width: 99%;
			height: 99%;
		}
	}

	// text
	.tsp {
		color: #eee;
	}
	p.extratitle {
		color: #eee;
		font-size: 20px;
		margin: 0;
		padding: 0;
	}
}

/* ----- Forrester Wave specific ----- */
/* 2023-09-07 */
#TheForm {
	scroll-margin: 130px;
}

// .m-single .col:first-child {
// 	width: 50%;
// }

/* hero bg with graphic in bottom right corner */
#ForresterWave {
	background: #dff2f6 url("../img/Forrester-wave-lp-01.svg") bottom right
		no-repeat;
	background-size: contain;
	background-size: auto 80%;

	@include medium {
		background-image: none;

		.m-single .col:first-child,
		.herocol {
			width: 100%;
			padding: 0;
		}

		img.hero-illustration {
			width: 100%;
		}

		.container h1 {
			font-size: 32px;
			line-height: 42px;
		}
	}

	.container {
		padding-top: 10px;
		padding-bottom: 10px;

		.extratitle {
			color: #2c3551;
			margin: 0.6em 0;
			font-size: 1.4em;
		}

		.buttons {
			margin-top: 2.5em;
		}
	}
}
