.m-accordion {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // headline
    .headline {
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 65px;
    }

    // text
    .tsp {
        margin-top: 20px;
    }

    // accordion-wrapper
    .accordion-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    // accordion-box
    .accordion-box {
        position: relative;
        width: 100%;
        transition: all 200ms ease-in-out;
        border-bottom: 1px solid $white-color;

        &:not(:last-child) {
            border-bottom: 1px solid $ink-spot-25;
        }

        &.not-active {
            border-bottom: 1px solid $white-color;
        }

        &.is-active {
            box-shadow: 0 5px 10px 0 rgba(44,53,81,0.12), 0 10px 40px 0 rgba(44,53,81,0.12);
            border-bottom: 1px solid $white-color;
            z-index: 10;
        }

        .accordion-title {
            position: relative;
            padding: 40px 45px;
            background-color: $white-color;

            @include medium {
                padding: 40px 25px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 40px;
                transform: translateY(-50%);
                background-image: url('../img/arrow-bottom-red.svg');
                width: 18px;
                height: 11px;
                transition: all 200ms ease-in-out;
            }

            &.is-active {

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 40px;
                    transform: translateY(-50%);
                    background-image: url('../img/arrow-bottom-red.svg');
                    width: 18px;
                    height: 11px;
                    transform: rotate(-180deg);
                }
            }

            h5 {
                position: relative;
                display: block;
                cursor: pointer;
                padding-right: 60px;

                @include medium {
                    font-size: 18px;
                }
            }


        }

        .accordion-content {
            position: relative;
            padding: 0 45px;
            margin-bottom: 45px;
            display: none;

            @include medium {
                padding: 0 25px;
            }
        }

    }

}
