.l-default {

    // container
    .container {
        padding-top: 48px;
        padding-bottom: 65px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        @include big {
            padding-top: 48px;
            padding-bottom: 65px;
        }
    }

}
