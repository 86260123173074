.u-box {
	position: relative;
	border: 3px solid $ink-spot-25;
	border-radius: 10px;
	height: 100%;

	.icon-wrapper {
		position: absolute;
		width: 100%;
		left: 0;
		top: -35px;
		text-align: center;
	}

	// icon
	.icon {
		display: inline-block;
		background-color: $white-color;
		padding: 0 20px;

		img {
			display: block;
			margin: 0 auto;
			padding: 0;
			width: auto;
			height: 65px;
			max-width: 160px;
			text-align: center;
		}
	}

	// content
	.content {
		position: relative;
		padding: 60px 40px 96px 40px;
		text-align: center;

		@include xsmall {
			padding: 60px 20px 96px 20px;
		}

		// headline
		h6 {
			margin-bottom: 10px;
		}

		// subtitle
		.subtitle {
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 1.71px;
			margin-bottom: 30px;
		}

		// button
		.u-button {
			margin-top: 34px;
		}
	}

	.buttons {
		position: absolute;
		left: 50%;
		bottom: 34px;
		text-align: center;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		transform: translateX(-50%);
	}
}
