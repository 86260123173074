.m-benefits {
    overflow-x: hidden;

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding;
            padding-bottom: $section-padding-mobile;
        }
    }

    //shape
    .shape {
        position: absolute;
        top: 0px;
        right: -180px;
        z-index: 10;

        @include big {
            display: none;
        }
    }

    // headline
    .headline {
        max-width: 860px;
        margin: 0 auto;
        margin-bottom: 65px;
        z-index: 10;

        @include medium {
            margin-bottom: 35px;
        }

        // text
        .tsp {
            margin-top: 20px;
        }
    }


    // wrapper
    .wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 10;

        @include medium {
            display: block;
        }
    }

    // benefit-box
    .benefit-box {
        position: relative;
        width: 100%;
        max-width: 322px;
        background-color: $ink-spot-8;
        margin: 0 5px;
        padding: 0;
        border-radius: 10px;
        text-align: center;

        @include medium {
            max-width: 100%;
            margin: 20px 0;
            background-color: $ink-spot-8!important;
        }

        // &:not(:nth-child(even)) {
        //     @include medium {
        //         display: none;
        //     }
        // }

        // content
        .content {
            padding-top: $section-padding-mobile;
            padding-bottom: 40px;

            @include medium {
                padding-top: 35px;
                padding-bottom: 15px;
            }
        }

        // title
        .title {
            margin-bottom: 45px;
        }

        // benefit-list
        .benefit-list {
            position: relative;
            margin: 0;
            margin-bottom: 65px;

            @include medium {
                margin-bottom: 20px;
            }
        }


        &:nth-child(2) {
            background-color: $grass-green-25;

            // benefit-list
            .benefit-list {

                &:after {
                    content: '';
                    position: absolute;
                    top: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-image: url('../img/benefit-line.svg');
                    background-repeat: no-repeat;
                    width: 700px;
                    height: 29px;
                    z-index: 20;

                    @include medium {
                        display: none;
                    }
                }
            }
        }


    }

    // slider navigation
    .slick-dots {
        margin-left: -20px;
        margin-right: -20px;

        li {
            margin: 0 20px;
            &.slick-active {
                .slider-dot {
                    background: $grass-green;
                }
            }
            .slider-dot {
                display: inline-block;
                width: 42px;
                height: 7px;
                background: $tmp-6;
                transition: all 200ms ease-in-out;

                &:hover {
                    background: $grass-green;
                }
            }
        }
    }

}
