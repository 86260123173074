.m-contact {
    height: 100%;

    // illustration
    .shape {
        position: absolute;
        top: 610px;
        left: -100px;
        opacity: 1;

        @include medium {
            display: none;
        }
    }

    // container
    .container {
        display: flex;
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include medium {
            flex-direction: column;
            padding-top: 50px;
            padding-bottom: $section-padding-mobile;
        }

    }

    //col
    .col {
        width: 50%;

        @include medium {
            width: 100%;
        }

        &:nth-child(1) {
            padding-right: 60px;

            @include medium {
                width: 100%;
                margin-bottom: 35px;
                padding-right: 0;
            }
        }

    }

    // col contact-info
    .contact-info {

        // title
        .title {
            margin-bottom: 20px;

            @include medium {
                text-align: center;
            }
        }

        // text
        .tsp {
            @include medium {
                text-align: center;
            }
        }

        a:not(.u-link) {
            color: $happy-red;
            &:hover {
                text-decoration: underline;
            }
        }

        // support
        .support {
            display: inline-block;
            position: relative;
            margin-top: 85px;
            background-color: $white-color;
            box-shadow: 0 5px 10px 0 rgba(44,53,81,0.12), 0 10px 40px 0 rgba(44,53,81,0.12);
            padding: 45px;

            @include medium {
                display: none;
            }

            // headline
            h6 {
                display: block;
                position: relative;
                margin-bottom: 20px;
                padding-top: 33px;

                &:before {
                    content: '';
                    position: absolute;
                    top: -13px;
                    left: 0;
                    background-image: url('../img/dots-contact.svg');
                    background-repeat: no-repeat;
                    width: 33px;
                    height: 33px;
                }
            }

            // link
            .u-link {
                margin-top: 30px;
                margin-left: -18px;
            }
        }

    }

    // col contact-form
    .contact-form {

        // support
        .support {
            display: none;
            position: relative;
            margin-top: 65px;
            background-color: $white-color;
            box-shadow: 0 5px 10px 0 rgba(44,53,81,0.12), 0 10px 40px 0 rgba(44,53,81,0.12);
            padding: 45px;

            @include medium {
                display: block;
            }

            a:not(.u-link) {
                color: $happy-red;
                &:hover {
                    text-decoration: underline;
                }
            }

            // headline
            h6 {
                display: block;
                position: relative;
                margin-bottom: 20px;
                padding-top: 33px;

                &:before {
                    content: '';
                    position: absolute;
                    top: -13px;
                    left: 0;
                    background-image: url('../img/dots-contact.svg');
                    background-repeat: no-repeat;
                    width: 33px;
                    height: 33px;
                }
            }

            // link
            .u-link {
                margin-top: 30px;
                margin-left: -18px;
            }
        }

    }
}
