.m-product-feature-list {
	// container
	.container {
		padding-top: 136px;
		padding-bottom: 136px;

		@include big {
			padding-top: 65px;
			padding-bottom: 65px;
		}
	}

	.u-news .col {
		margin-top: 40px;
	}

	.u-news .box {
		/* UI Properties */
		background: #f9f9fc 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 10px #00000029;
		padding: 30px;
		padding-bottom: 90px;
		border-radius: 13px;
		opacity: 1;
	}

	// button - learn more/discover more
	.u-news .box .content .buttons {
		// background: red;
		position: absolute;
		left: 50%;
		bottom: 36px;
		text-align: center;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	.u-news .box .content h5 {
		align-items: center;
		margin: 10px 0 0 0;
		padding: 10px 0;

		&:before {
			content: "";
			// padding: 20px 0;
			display: inline-block;
			vertical-align: middle;
			background: url(../img/list-circle.svg) no-repeat 0 0;
			width: 38px;
			height: 45px;
			margin-right: 15px;
		}

		&.contact-center:before {
			background-image: url(../img/icons_product_overview/Icon_CallCenter.svg);
		}
		&.collaboration:before {
			background-image: url(../img/icons_product_overview/Icon_Administration.svg);
		}
		&.phone-systems:before {
			background-image: url(../img/icons_product_overview/Icon_PhoneSystems.svg);
		}
		&.service-and-support:before {
			background-image: url(../img/icons_product_overview/Icon_ServiceAndSupport.svg);
		}
		&.integrations:before {
			background-image: url(../img/icons_product_overview/Icon_Integrations.svg);
		}
	}
	.u-news .box .content h5.imageheader {
		&:before {
			display: none;
		}
	}

	.u-news .box .content h6 {
		font-size: 18px;
		margin: 25px 0 0 0;
	}

	// bulltetpoints
	.u-news .box .content ul.bulletpoints {
		margin: 14px 0 14px 0;
	}
	.u-news .box .content ul.bulletpoints li {
		line-height: 24px;
		margin: 0;
		padding: 1px 0 1px 34px;
		margin: 1px 0 5px 0;
		list-style: none;
		background-image: url(../img/list-circle.svg);
		background-repeat: no-repeat;
		background-position: 0 2px;
		background-size: 20px;
	}

	// headline
	h3 {
		font-size: 50px;
		line-height: 55px;
		margin-bottom: 45px;
		margin-top: 33px;
		z-index: 20;
	}
}

//
// 2024-03-21 Roaming data table
//

#Roaming {
	padding-bottom: 34px;
}
#Roaming h3 {
	font-size: clamp(30px, 4vw, 50px);
	margin-right: 20%;
	margin-left: 20%;
}

@media (min-width: 1280px) {
	#Roaming .u-news .col {
		width: 25%;
	}
}
@include medium {
	#Roaming h3 {
		margin-right: 15%;
		margin-left: 15%;
		margin-bottom: 20px;
	}
}

@include small {
	#Roaming h3 {
		text-align: left;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 10px;
		line-height: 1.2;
	}
}
@include xsmall {
	#Roaming h3 {
		text-align: left;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 5px;
	}
}

#Roaming .u-news .box {
	padding-bottom: 20px;
	padding-top: 10px;
	pointer-events: none;
}
#Roaming .u-news .box:hover {
	background: #f9f9fc 0% 0% no-repeat padding-box;
}
#Roaming .u-news .box .content ul.bulletpoints li {
	background-image: url(../img/Check_mark.svg);
	background-position: 0 5px;
}
#Roaming .u-news .box .content h5 {
	line-height: 10px;
	padding-bottom: 30px;
}
#Roaming .u-news .box .content h5:before {
	background: url(../img/Roaming_Icon.svg) no-repeat 0 0;
	display: block;
	width: 56px;
	height: 57px;
	margin-bottom: 40px;
}
