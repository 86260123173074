.m-directors {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // headline
    .headline {
        max-width: 960px;
        margin: 0 auto;
    }

    // text
    .tsp {
        margin-top: 20px;
    }

    // wrapper
    .wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -40px;
        box-sizing: border-box;

        @include big {
            margin: 0;
        }

        @include medium {
            justify-content: flex-start;
        }
    }


}
