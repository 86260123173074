.m-featured-quote {

    //container
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 136px;
        padding-bottom: 136px;

        @include big {
            padding-top: 65px;
            padding-bottom: 65px;
        }

        @include medium {
            justify-content: flex-end;
            max-width: 100%;
            padding: 0;
            padding-top: 65px;
            padding-bottom: 65px;
        }
    }

    .title_section {
        display: block;
        width: 100%;
        margin-bottom: 40px;
    }

    // wrapper
    .wrapper {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;

        @include big {
            max-width: 890px;
        }

        @include medium {
            max-width: 750px;
        }

        @include small {
            max-width: none;
        }

        // content
        .content {
            position: absolute;
            top: 176px;
            right: 230px;
            width: 490px;

            @include big {
                width: 350px;
                top: 115px;
            }

            @include medium {
                right: 80px;
            }

            @include small {
                top: 460px;
                left: 50%;
                transform: translateX(-50%);
                width: 290px;
            }

            &.light {
                color: $white-color;

                .like-h4 {
                    color: $white-color;
                }

                .overline-2 {
                    color: $white-color!important;
                }
            }

            .like-h4 {
                position: relative;
                font-size: 30px;
                line-height: 36px;

                &.small {
                    font-size: 20px;
                    line-height: 28px;

                    span {
                        &:first-child {
                            margin-left: -16px;
                        }
                    }

                    @include big {
                        font-size: 18px;
                        line-height: 28px;

                        span {
                            &:first-child {
                                margin-left: -14px;
                            }
                        }
                    }
                }

                @include big {
                    font-size: 18px;
                    line-height: 28px;
                }

                span {
                    color: $grass-green;

                    &:first-child {
                        margin-left: -25px;

                        @include big {
                            margin-left: -14px;
                        }
                    }
                }
            }

            // overline
            .overline-2 {
                display: block;
                margin-top: 27px;

                @include big {
                    margin-top: 13px;
                    max-width: 325px;
                }

                @include xsmall {
                    margin-top: 27px;
                }
            }

            // link
            .u-button.default {
                margin-top: 36px;

                @include big {
                    margin-top: 20px;
                }

                @include xsmall {
                    margin-top: 36px;
                }

                &:before {
                    @include small {
                        width: 38px;
                    }
                }

                &:hover:before {
                    width: 100%;
                }

                span {
                    padding: 13px 52px 11px 23px;
                }


            }
        }

        // img
        .animated-image-parallax {
            position: absolute;
            top: 75px;
            left: 0;
            z-index: 10;

            @include big {
                max-width: 277px;
            }

            @include small {
                top: 125px;
                left: 50%;
                transform: translate(-50%, 0);
                max-width: 290px;
                width: 100%;
            }

            img {
                display: block;
                width: 100%;
                object-fit: cover;
            }
        }

        // logotype
        .quote-logo {
            position: absolute;
            top: 10px;
            right: 158px;
            max-width: 150px;

            @include big {
                top: -30px;
                right: 100px;
            }

            @include medium {
                right: 20px;
            }

            @include small {
                top: -10px;
            }
        }

        // background wrapper
        .wrapper-bg {
            position: relative;

            @include medium {
                overflow-x: hidden;
            }

            @include small {
                width: 100%;
            }

            .quote-bg {
                width: 1205px;
                height: 642px;
                margin: 0;
                padding: 0;
                display: block;

                @include big {
                    width: 853px;
                    height: 468px;
                }

                @include small {
                    width: auto;
                    height: 763px;
                    margin-left: -270px;
                    margin-top: 144px;
                    margin-right: unset;
                }
            }

        }
    }

}


// quote-customer
.quote-customer {
    background-color: $ink-spot;

    // container
    .container {
        padding-top: 136px;
        padding-bottom: 136px;

        @include big {
            padding-top: 65px;
            padding-bottom: 65px;
        }
    }

    //shape
    .shape {
        position: absolute;
        top: 30px;
        right: -70px;

        @include big {
            display: none;
        }
    }

    // content-quote
    .content-quote {
        margin: 0 auto;
        text-align: center;
        max-width: 840px;
        z-index: 10;

        .like-h3 {
            position: relative;
            color: $white-color;

            span {
                color: $grass-green;

                &:first-child {
                    margin-left: -30px;

                    @include big {
                        margin-left: -20px;
                    }
                }
            }
        }

        // overline
        .overline-2 {
            display: block;
            margin-top: 35px;
            color: $white-color;
        }

    }

}
