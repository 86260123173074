.m-team {

    // container
    .container {
        max-width: 1200px;
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // icon
    .icon {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 48px;
        height: 52px;
        margin-bottom: 30px;
        position: relative;

        @include medium {
            width: 38px;
            height: 38px;
        }

        .mfiDot2,
        .mfiDot3 {
            position: absolute;
            top: 0;
            left: 0;
        }
    }


    // headline
    .headline {
        max-width: 1060px;
        margin: 0 auto;
        margin-bottom: 65px;

        @include medium {
            margin-bottom: 35px;
        }
    }

    // text
    .tsp {
        color: $black-color;
        margin-top: 20px;

        strong {
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }

    // wrapper
    .wrapper {
        display: flex;
        // justify-content: space-around;
        justify-content: center;
        padding-bottom: 20px;
        flex-wrap: wrap;
        justify-content: center;
        margin: -20px -20px;
        // margin-bottom: -40px;

        @include medium {
            justify-content: center;
        }

        @include small {
            margin: 0;
        }
    }

    // col
    .col-team {
        flex: 0 0 calc(33.33% - 40px);
        max-width: 320px;
        margin: 20px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        @include medium {
            flex: 0 0 50%;
        }

        @include small {
            flex: 0 0 100%;
            margin: 0;
        }

        //box
        .box {
            position: relative;
            height: 100%;
            padding: 15px 15px 30px;
            background: transparent;
            transition: all 200ms ease-in-out;
            cursor: pointer;

            &:hover {
                background: $white-color;
                box-shadow: 0 5px 10px 0 rgba(44,53,81,0.12), 0 10px 40px 0 rgba(44,53,81,0.12);
            }

            // image
            img {
                display: block;
                margin: 0 auto;
                padding: 0;
                object-fit: cover;
                max-width: 100%;
                height: auto;
            }

            // name
            h4 {
                margin: 15px 0;
            }

            // positioin
            .position {
                display: block;
                margin-bottom: 20px;
            }

            .u-link {
                margin-left: -18px;
            }

            &:hover .u-link {
                color: $white-color;
                &:before {
                    width: 92%;
                    z-index: 0;
                }
            }

        }

    }

}
