.u-sidebar {
	position: relative;
	box-sizing: content-box;
	width: 375px;

	&:not(.customer) {
		@include big {
			display: none;
		}
	}

	&.customer {
		@include big {
			width: 100%;
		}
	}

	// box-navigation
	.box-navigation {
		position: absolute;
		top: 0;
		margin-bottom: 20px;
		margin-left: 40px;
		padding: 40px 40px 40px 65px;
		width: 100%;
		width: 335px;
		background-color: $white-color;
		box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.12),
			0 10px 40px 0 rgba(44, 53, 81, 0.12);
		z-index: 10;

		@include big {
			width: 250px;
			padding: 40px;
		}

		// overline
		.overline-2 {
			display: block;
			margin-bottom: 20px;
			font-weight: bold;
		}

		// list
		ul {
			display: block;
			margin: 0;
			padding: 0;

			li {
				display: block;
				width: 100%;
				padding: 20px 0;
				// 20230927 changed from 1.2 to 1.5 for multiline headers
				// e.g. Telavox Admin Portal Accessibility
				line-height: 1.5;

				a {
					position: relative;
					display: inline-block;
					transition: all 200ms ease-in-out;

					&:before {
						opacity: 0;
						content: "";
						position: absolute;
						top: 50%;
						left: -65px;
						width: 5px;
						height: 40px;
						background-color: $grass-green;
						transform: translateY(-50%);
						transition: all 200ms ease-in-out;

						@include big {
							left: -40px;
						}
					}

					&:hover {
						color: $grass-green;
					}

					&:hover:before {
						opacity: 1;
					}

					// active
					&.is-active {
						color: $grass-green;

						&:before {
							opacity: 1;
							content: "";
							position: absolute;
							top: 50%;
							left: -65px;
							width: 5px;
							height: 40px;
							background-color: $grass-green;
							transform: translateY(-50%);
							transition: all 200ms ease-in-out;

							@include big {
								left: -40px;
							}
						}
					}
				}
			}
		}

		// button
		.u-button {
			margin-top: 20px;
		}
	}

	// box-navigation-default
	.box-navigation-default {
		position: absolute;
		top: 0;
		margin-bottom: 20px;

		// position: sticky;
		// top: 125px;
		// margin-bottom: 85px;
		margin-left: 40px;
		padding: 40px 40px 40px 65px;
		width: 100%;
		width: 335px;
		background-color: $white-color;
		box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.12),
			0 10px 40px 0 rgba(44, 53, 81, 0.12);

		@include big {
			width: 250px;
			padding: 40px;
		}

		// list
		ul {
			display: block;
			margin: 0;
			padding: 0;

			li {
				display: block;
				width: 100%;
				padding: 20px 0;
				line-height: 1.5;

				a {
					position: relative;
					display: inline-block;
					transition: all 200ms ease-in-out;

					&:before {
						opacity: 0;
						content: "";
						position: absolute;
						top: 50%;
						left: -65px;
						width: 5px;
						height: 40px;
						background-color: $grass-green;
						transform: translateY(-50%);
						transition: all 200ms ease-in-out;

						@include big {
							left: -40px;
						}
					}

					&:hover {
						color: $grass-green;
					}

					&:hover:before {
						opacity: 1;
					}
				}

				&.current_page_item {
					a {
						color: $grass-green;

						&:before {
							opacity: 1;
							content: "";
							position: absolute;
							top: 50%;
							left: -65px;
							width: 5px;
							height: 40px;
							background-color: $grass-green;
							transform: translateY(-50%);
							transition: all 200ms ease-in-out;

							@include big {
								left: -40px;
							}
						}
					}
				}
			}
		}
	}

	// box-navigation-customer
	.box-navigation-customer {
		position: absolute;
		top: 0;
		margin-bottom: 20px;
		margin-left: 40px;
		padding: 40px 40px 40px 65px;
		width: 100%;
		width: 335px;
		background-color: $white-color;
		box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.12),
			0 10px 40px 0 rgba(44, 53, 81, 0.12);
		z-index: 10;

		@include big {
			position: relative !important;
			top: 0;
			width: 100%;
			padding: 40px;
			margin-left: 0;
			margin-bottom: 40px;
		}

		// overline
		.overline-2 {
			display: block;
			margin-bottom: 20px;
			font-weight: bold;

			&.subtitle {
				font-weight: 400;
				margin-bottom: 7px;
			}
		}

		.text {
			margin-bottom: 20px;
		}

		ul {
			margin-bottom: 20px;

			li {
				font-size: 16px;
				font-weight: 300;
				line-height: 24px;

				a {
					color: $happy-red;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		// button
		.u-button {
			margin-top: 20px;
		}
	}
}
