// featured integrations
.m-featured-integrations {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: 0;
        }
    }

    // headline
    h3 {
        @include big {
            font-size: 30px;
        }
    }

    // wrapper
    .wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        box-sizing: border-box;
        margin: 0;
        margin-top: 95px;

        @include big {
            flex-wrap: wrap;
            margin: 0;
            margin-top: 65px;
        }
    }

    // col
    .col {
        width: 410px;
        padding: 0 25px;

        @include big {
            width: 50%;
            max-width: 370px;
            margin-bottom: 73px;
            padding: 0 20px;

            &:last-child {
                margin-bottom: 65px;
            }
        }

        @include small {
            width: 100%;
        }
    }

}
