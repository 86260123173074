// lightbox slick
.slick-lightbox {
    background: $white-color!important;
}
.slick-lightbox-close::before {
    color: $grass-green!important;
    opacity: 1!important;
    font-size: 40px!important;
    width: auto!important;
    height: auto!important;
}
.slick-next::before, .slick-prev::before {
    font-size: 24px!important;
    line-height: 1!important;
    color: $grass-green!important;
    opacity: 1!important;
}
.slick-next, .slick-prev {
    z-index: 10;
}

// lightbox lity
.lity {
    background: rgba(250,250,250,.85)!important;
}
.lity-close {
    top: 15px!important;
    right: 15px!important;
    font-family: slick!important;
    font-size: 40px!important;
    color: $grass-green!important;
    text-shadow: none!important;
    width: auto!important;
    height: auto!important;
    line-height: 1!important;

    &:hover {
        text-shadow: none!important;
        font-size: 40px!important;
        line-height: 1!important;
    }
}
