.m-cta {
    background-color: $grass-green-8;

    position: relative;
    overflow: hidden!important;

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;
        text-align: center;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // content
    .content {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 900px;
        margin: 0 auto;
        z-index: 10;
    }

    // headline
    h2 {
        margin-bottom: 15px;

        @include small {
            margin-bottom: 27px;
        }
    }

    // text
    .sp {
        display: block;
        margin: 0 auto;
        max-width: 680px;

        @include big {
            max-width: 510px;
        }
    }

    // button
    .buttons {
        margin-top: 50px;

        @include big {
            margin-top: 30px;
        }

        @include small {
            margin-top: 25px;
        }

        .u-button {
            margin: 10px;
        }
    }

    .shape-wrapper {
        position: absolute;
        overflow: hidden;
    }


    &.type-1 {

        //shape
        .shape-u {
            position: absolute;
            top: 0;
            left: 105px;

            @include big {
                height: 220px;
                left: 45px;
            }

            @include small {
                display: none;
            }
        }

        .shape {
            position: absolute;
            bottom: -45px;
            right: -105px;
            opacity: 1;

            @include big {
                height: 200px;
                right: -20px;
            }

            @include small {
                display: none;
            }
        }

    }

    &.type-2 {

        //shape
        .shape-u {
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 0;

            &.loaded {
                top: 65px;
                left: -250px;
                opacity: 1;

                @include big {
                    top: 30px;
                    height: 220px;
                }

            }

            @include small {
                display: none;
            }

        }

        .shape {
            position: absolute;
            bottom: 235px;
            right: -105px;

            @include big {
                height: 200px;
                bottom: -25px;
            }

            @include small {
                display: none;
            }
        }

    }

    &.type-3 {

        //shape
        .shape-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

        }
        .shape-left {
            position: absolute;
            top: -100px;
            left: 45px;
            opacity: 1;

            @include big {
                display: none;
            }
        }

        .shape-right {
            position: absolute;
            bottom: -120px;
            right: -200px;
            opacity: 1;

            @include big {
                display: none;
            }
        }


    }

}
