.m-hero-internal {
	position: relative;
	overflow: hidden !important;

	.container {
		justify-content: center;
		min-height: 350px;
	}

	// DEFAULT
	&.default {
		//container
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 65px;
			padding-bottom: 65px;

			@include medium {
				flex-direction: column;
				padding-left: 40px;
				padding-top: 35px;
				padding-bottom: 35px;
			}

			@include xsmall {
				padding-left: 20px;
			}
		}

		//col
		.col {
			width: 50%;

			@include medium {
				width: 75%;
			}

			@include xsmall {
				width: 100%;
			}

			&:nth-child(1) {
				padding-right: 30px;
				width: 45%;

				@include big {
					padding-right: 60px;
				}

				@include medium {
					margin-bottom: 35px;
					padding-right: 0;
					width: 75%;
				}

				@include xsmall {
					width: 100%;
				}
			}
		}

		//content
		.content {
			@include big {
				margin-top: 0;
				margin-bottom: 18px;
			}
		}

		// hero-illustration
		img.hero-illustration {
			display: block;
			margin: 0;
			padding: 0;
			object-fit: cover;
			max-width: 100%;

			@include medium {
				display: block;
				margin: 0 auto;
				width: 100%;
			}
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}
	}

	// FEATURES
	&.features {
		//container
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 65px;
			padding-bottom: 65px;

			@include medium {
				flex-direction: column;
				padding-left: 40px;
				padding-top: 35px;
				padding-bottom: 35px;
			}

			@include xsmall {
				padding-left: 20px;
			}
		}

		//col
		.col {
			width: 50%;

			@include medium {
				width: 75%;
			}

			@include xsmall {
				width: 100%;
			}

			&:nth-child(1) {
				padding-right: 30px;
				width: 45%;

				@include big {
					padding-right: 60px;
				}

				@include medium {
					margin-bottom: 35px;
					padding-right: 0;
					width: 75%;
				}

				@include xsmall {
					width: 100%;
				}
			}
		}

		//content
		.content {
			@include big {
				margin-top: 0;
				margin-bottom: 18px;
			}
		}

		// hero-illustration
		img.hero-illustration {
			display: block;
			margin: 0;
			padding: 0;
			object-fit: cover;
			max-width: 100%;

			@include medium {
				display: block;
				margin: 0 auto;
				width: 100%;
			}
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		// buttons
		.buttons {
			white-space: nowrap;
			margin-top: 50px;
			margin-bottom: 10px;

			@include big {
				margin-top: 35px;
			}

			// link
			.u-link {
				margin-left: -20px;

				@include medium {
					margin-left: -20px;
				}
			}
		}

		// features-background-top-circuit
		&.features-background-top-circuit {
			// shape
			.shape {
				position: absolute;
				top: 0px;
				left: 0px;
				opacity: 0;

				&.loaded {
					top: -150px;
					left: 70px;
					opacity: 1;

					@include big {
						top: -250px;
						left: 20px;
					}
				}

				@include medium {
					display: none;
				}
			}
		}

		// features-background-bottom-circuit
		&.features-background-bottom-circuit {
			// shape
			.shape {
				position: absolute;
				bottom: 0px;
				left: 0px;
				opacity: 0;

				&.loaded {
					bottom: -150px;
					left: 250px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}

		// features-background-left-circuit
		&.features-background-left-circuit {
			// shape
			.shape {
				position: absolute;
				top: 0px;
				left: 0px;
				opacity: 0;

				&.loaded {
					top: 30px;
					left: -180px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}
	}

	// BUSINESS
	&.business {
		//container
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 65px;
			padding-bottom: 65px;

			@include medium {
				flex-direction: column;
				padding-left: 40px;
				padding-top: 35px;
				padding-bottom: 35px;
			}

			@include xsmall {
				padding-left: 20px;
			}
		}

		//col
		.col {
			width: 50%;

			@include medium {
				width: 75%;
			}

			@include xsmall {
				width: 100%;
			}

			&:nth-child(1) {
				padding-right: 30px;
				width: 45%;

				@include big {
					padding-right: 60px;
				}

				@include medium {
					margin-bottom: 35px;
					padding-right: 0;
					width: 75%;
				}

				@include xsmall {
					width: 100%;
				}
			}
		}

		//content
		.content {
			@include big {
				margin-top: 0;
				margin-bottom: 18px;
			}
		}

		// hero-illustration
		img.hero-illustration {
			display: block;
			margin: 0;
			padding: 0;
			object-fit: cover;
			max-width: 100%;

			@include medium {
				display: block;
				margin: 0 auto;
				width: 100%;
			}
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		// buttons
		.buttons {
			white-space: nowrap;
			margin-top: 50px;
			margin-bottom: 10px;

			@include big {
				margin-top: 35px;
			}

			@include small {
				white-space: normal;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}

			.u-button {
				margin-right: 20px;

				@include small {
					margin-right: 0;
				}
			}

			.u-link {
				@include small {
					margin-top: 20px;
					// margin-left: -18px;
				}
			}
		}

		// business-outcomes-background-green-ball-cup
		&.business-outcomes-background-green-ball-cup {
			// shape
			.shape {
				position: absolute;
				top: 80px;
				left: -110px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}
		}

		// business-outcomes-background-green-ball-tube
		&.business-outcomes-background-green-ball-tube {
			// shape
			.shape {
				position: absolute;
				top: -40px;
				left: 30%;
				opacity: 1;

				@include medium {
					display: none;
				}
			}
		}

		// business-outcomes-background-grey-ball-tube
		&.business-outcomes-background-grey-ball-tube {
			// shape
			.shape {
				overflow: visible;
				position: absolute;
				bottom: -80px;
				left: 25%;
				opacity: 1;

				@include medium {
					display: none;
				}
			}
		}

		// business-outcomes-background-grey-ball-cup
		&.business-outcomes-background-grey-ball-cup {
			// shape
			.shape {
				position: absolute;
				bottom: 30px;
				left: -120px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}
		}
	}

	// INTEGRATIONS
	&.integrations {
		//content
		.content {
			position: relative;
			text-align: center;
			z-index: 10;
			max-width: 800px;
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		// logotype
		img.logotype {
			display: block;
			margin: 0 auto;
			padding: 0;
			margin-bottom: 35px;
			height: 65px;
			max-width: 160px;
		}

		// integrations-main
		&.integrations-main {
			//container
			.container {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 120px;
				padding-bottom: 120px;

				@include medium {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}

			// shape
			.shape-left {
				position: absolute;
				top: -80px;
				left: 0px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				top: 90px;
				right: -80px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}
		}

		// integrations-single-1
		&.integrations-single-1 {
			//container
			.container {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 65px;
				padding-bottom: 65px;

				@include medium {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}

			// shape
			.shape-left {
				position: absolute;
				top: 80px;
				left: 0px;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				bottom: -50px;
				right: -80px;

				@include medium {
					display: none;
				}
			}
		}

		// integrations-single-2
		&.integrations-single-2 {
			//container
			.container {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 65px;
				padding-bottom: 65px;

				@include medium {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}

			// shape
			.shape-left {
				position: absolute;
				bottom: 70px;
				left: 0px;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				top: 0px;
				right: 0px;
				opacity: 0;

				&.loaded {
					top: -80px;
					right: 0px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}

		// integrations-single-3
		&.integrations-single-3 {
			//container
			.container {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 65px;
				padding-bottom: 65px;

				@include medium {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}

			// shape
			.shape-left {
				position: absolute;
				top: 80px;
				left: 0px;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				bottom: 0px;
				right: 0px;
				opacity: 0;

				&.loaded {
					bottom: 80px;
					right: -80px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}
	}

	// CUSTOMER
	&.customer {
		//content
		.content {
			position: relative;
			text-align: center;
			z-index: 10;
			max-width: 800px;
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		// logotype
		img.logotype {
			display: block;
			margin: 0 auto;
			padding: 0;
			margin-bottom: 35px;
			// 2024-09-19 changed from height ti max-height
			max-height: 150px;
			max-width:50%;
		}

		// buttons
		.buttons {
			white-space: nowrap;
			margin-top: 50px;

			@include big {
				margin-top: 35px;
			}
		}

		// customer-main
		&.customer-main {
			//container
			.container {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 120px;
				padding-bottom: 120px;

				@include medium {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}

			// shape
			.shape {
				position: absolute;
				top: -20px;
				right: -80px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}
		}

		// customer-single-1
		&.customer-single-1 {
			//container
			.container {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 65px;
				padding-bottom: 65px;

				@include medium {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}

			// shape
			.shape-left {
				position: absolute;
				top: 80px;
				left: 0px;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				bottom: 70px;
				right: 0px;
				opacity: 0;

				&.loaded {
					bottom: 70px;
					right: 0px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}

		// customer-single-2
		&.customer-single-2 {
			//container
			.container {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 65px;
				padding-bottom: 65px;

				@include medium {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}

			// shape
			.shape-left {
				position: absolute;
				bottom: 70px;
				left: 0px;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				top: 0px;
				right: 0px;
				opacity: 0;

				&.loaded {
					top: -80px;
					right: 0px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}

		// customer-single-3
		&.customer-single-3 {
			//container
			.container {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 65px;
				padding-bottom: 65px;

				@include medium {
					padding-top: 60px;
					padding-bottom: 60px;
				}
			}

			// shape
			.shape-left {
				position: absolute;
				top: 80px;
				left: 0px;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				bottom: 0px;
				right: 0px;
				opacity: 0;

				&.loaded {
					bottom: 80px;
					right: -80px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}
	}

	// PARTNER
	&.partner {
		//container
		.container {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 65px;
			padding-bottom: 65px;

			@include medium {
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		//content
		.content {
			position: relative;
			text-align: center;
			z-index: 10;
			max-width: 800px;
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		// buttons
		.buttons {
			white-space: nowrap;
			margin-top: 50px;
			margin-bottom: 10px;

			@include big {
				margin-top: 35px;
			}

			// link
			.u-link {
				margin-left: -20px;

				@include medium {
					margin-left: -20px;
				}
			}
		}

		// partner-page
		// partner-dealer
		&.partner-page,
		&.partner-dealer {
			// shape
			.shape-left {
				position: absolute;
				bottom: -20px;
				left: 0px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				top: 0px;
				right: 0px;
				opacity: 0;

				&.loaded {
					top: 45px;
					right: -80px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}

		// partner-operators
		&.partner-operators {
			// shape
			.shape-left {
				position: absolute;
				top: 90px;
				left: -80px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				bottom: 60px;
				right: -60px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}
		}

		// partner-blank
		&.partner-blank {
			// shape
			.shape-left {
				position: absolute;
				top: -65px;
				left: 0px;
				opacity: 1;

				@include medium {
					display: none;
				}
			}

			// shape
			.shape-right {
				position: absolute;
				bottom: 50px;
				right: 0px;
				opacity: 0;

				&.loaded {
					bottom: 50px;
					right: -65px;
					opacity: 1;
				}

				@include medium {
					display: none;
				}
			}
		}
	}

	// SERVICES-SUPPORT
	&.services-support {
		//container
		.container {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 65px;
			padding-bottom: 65px;

			@include medium {
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		//content
		.content {
			position: relative;
			text-align: center;
			z-index: 10;
			max-width: 800px;
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		// buttons
		.buttons {
			white-space: nowrap;
			margin-top: 50px;
			margin-bottom: 10px;

			@include big {
				margin-top: 35px;
			}

			// link
			.u-link {
				margin-left: -20px;

				@include medium {
					margin-left: -20px;
				}
			}
		}

		// shape
		.shape-left {
			position: absolute;
			top: -65px;
			left: 0px;
			opacity: 1;

			@include medium {
				display: none;
			}
		}

		// shape
		.shape-right {
			position: absolute;
			top: 120px;
			right: 0px;

			@include medium {
				display: none;
			}
		}
	}

	// SPECS
	&.specs {
		//content
		.content {
			position: relative;
			text-align: center;
			z-index: 10;
			max-width: 960px;
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		//container
		.container {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 120px;
			padding-bottom: 120px;

			@include medium {
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		// shape
		.shape-left {
			position: absolute;
			top: 40px;
			left: -80px;
			opacity: 1;

			@include medium {
				display: none;
			}
		}

		// shape
		.shape-right {
			position: absolute;
			bottom: 20px;
			right: -120px;
			opacity: 1;

			@include medium {
				display: none;
			}
		}
	}

	// pillar
	&.pillar {
		//content
		.content {
			position: relative;
			text-align: center;
			z-index: 10;
			max-width: 960px;
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		// buttons
		.buttons {
			display: block;
			margin: 0 auto;
			margin-top: 40px;
			padding: 0;
			text-align: center;
		}

		//container
		.container {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 120px;
			padding-bottom: 120px;

			@include medium {
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		// shape
		.shape-left {
			position: absolute;
			top: 25px;
			left: 0px;
			opacity: 0;

			&.loaded {
				top: 25px;
				left: -140px;
				opacity: 1;
			}

			@include medium {
				display: none;
			}
		}

		// shape
		.shape-right {
			position: absolute;
			top: 0px;
			right: 0px;
			opacity: 0;

			&.loaded {
				top: 0px;
				right: -330px;
				opacity: 1;
			}

			@include medium {
				display: none;
			}
		}
	}

	// APPS
	&.apps {
		//content
		.content {
			position: relative;
			text-align: center;
			z-index: 10;
			max-width: 800px;
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		//container
		.container {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 120px;
			padding-bottom: 120px;

			@include medium {
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		// shape
		.shape {
			position: absolute;
			top: 30px;
			right: -200px;
			opacity: 1;

			@include medium {
				display: none;
			}
		}
	}

	// CONFERENCE
	&.conference {
		//content
		.content {
			position: relative;
			text-align: center;
			z-index: 10;
			max-width: 800px;
		}

		// headline
		h1 {
			margin-bottom: 24px;
		}

		//container
		.container {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 120px;
			padding-bottom: 120px;

			@include medium {
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		// shape
		.shape-left {
			position: absolute;
			top: 30px;
			left: -250px;
			opacity: 1;

			@include medium {
				display: none;
			}
		}

		.shape-right {
			position: absolute;
			top: 50px;
			right: 75px;
			opacity: 0;

			&.loaded {
				top: 50px;
				right: 75px;
				opacity: 1;
			}

			@include medium {
				display: none;
			}
		}
	}
}

#WellConnected .huge-text {
	color: #fff;
}
#WellConnected .tsp {
	color: #eee;
}
#WellConnected {
	background-image: url(../img/hero/background_S.webp);
	background-repeat: repeat;
}
