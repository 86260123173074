.m-app {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }


    // wrapper
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -20px;
        box-sizing: border-box;

        @include big {
            justify-content: center;
            flex-direction: column;
            margin: 0;
        }

        // col
        .col {
            padding: 0 20px;

            &.content-text {
                width: 547px;

                @include big {
                    margin-top: 0;
                    width: 100%;
                }
            }

            @include big {
                width: 100%;
                text-align: center;
            }

            &:last-child {
                @include big {
                    margin-top: 40px;
                }
            }

        }


        // headline
        h4 {
            margin-bottom: 30px;
        }

        // apps
        .get-apps {
            position: relative;
            margin-top: 40px;
            margin-left: -20px;
            margin-right: -20px;
            display: flex;
            flex-wrap: wrap;

            @include big {
                justify-content: center;
                margin: 0;
                margin-top: 40px;
            }

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 10px 20px;
                background-color: $black-color;
                border-radius: 3px;
                width: 158px;
                height: 48px;

                a {

                    @include small {
                        margin: 10px;
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                    }
                }


            }

        }


        // image
        .bg {
            display: block;
            margin: 0 auto;
            padding: 0;
            max-width: 100%;

            @include big {
                display: none;
            }
        }

        // image mobile
        .bg-mobile {
            display: none;
            margin: 30px auto;
            padding: 0;
            max-width: 100%;

            @include big {
                display: block;
            }
        }


    }


    &.right {

        // wrapper
        .wrapper {
            // col
            .col {
                &.content-text {
                    order: 1;

                    @include big {
                        order: -1;
                    }
                }
            }
        }
    }

}
