.m-announcement {
	position: relative;
	width: 100%;
	max-width: 1269px;
	margin: 0 auto;
	margin-top: 65px;
	box-shadow: 0 5px 10px 0 rgba(44, 53, 81, 0.12),
		0 10px 40px 0 rgba(44, 53, 81, 0.12);

	@include big {
		width: 90%;
	}

	// container
	.container {
		padding: 0;
		background-color: $white-color;
	}

	// content
	.content {
		position: relative;
		padding: 24px 59px 24px 24px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;

		@include big {
			flex-direction: column;
			align-items: flex-start;
			//padding: 20px 30px 30px 120px;
		}

		@include small {
			//padding: 30px 15px 33px 68px;
		}

		// icon
		.picture {
			position: relative;
			display: block;
			width: 33px;
			height: 33px;
			margin-right: 20px;

			@include big {
				// position: absolute;
				// top: 23px;
				// left: 67px;
				display: none;
			}

			@include small {
				// top: 34px;
				// left: 24px;
				display: none;
			}

			.m-a-dot1 {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}

			.m-a-dot2 {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
			}

			.m-a-dot3 {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
			}
		}

		// graphic
		.graphic {
			//position: relative;
			//display: block;
			width: 200px;
			//height: 100px;
			margin-right: 40px;
			padding-right: 30px;
			border-right: 1px solid #000;

			@include big {
				position: relative;
				border-right: none;
				margin-right: 0;
				margin-bottom: 20px;
			}

			@include small {
				position: relative;
				border-right: none;
				margin-right: 0;
				margin-bottom: 10px;
			}
		}

		//title
		h6 {
			display: block;
			position: relative;
			flex-shrink: 3;
			margin-right: 30px;

			@include big {
				display: inline-block;
				font-size: 16px;
				line-height: 24px;
				margin-right: 0;
				margin-bottom: 8px;
			}
		}

		//text
		p {
			display: block;
			margin-right: 5px;

			@include big {
				display: block;
				margin-right: 0;
				margin-bottom: 18px;
			}

			@include small {
				line-height: 24px;
			}
		}

		// download-link
		.u-link {
			@include big {
				margin-left: -19px;
			}
		}
	}
}
