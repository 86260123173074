.m-boxes {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    &.small_padding_top .container {
        padding-top: $section-padding-mobile;

        @include big {
            padding-top: $section-padding-mobile;
        }
    }

    &.small_padding_bottom .container {
        padding-bottom: $section-padding-mobile;

        @include big {
            padding-bottom: $section-padding-mobile;
        }
    }

    // headline
    .headline {
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 65px;
    }

    // text
    .tsp {
        &:not(:first-child) {
            margin-top: 20px;
        }

        &:not(.last-child) {
            margin-bottom: 20px;
        }
    }

    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    // wrapper
    .wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: -20px;
    }

}
