.u-button {
    position: relative;
    display: inline-block;
    border-radius: 19px;
    text-decoration: none;
    transition: all 200ms ease-in-out;

    &.default {
        color: $white-color;
        background-color: $happy-red;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50px;
            width: 38px;
            height: 100%;
            background: $happy-red-hover;
            transition: all 400ms ease;

            @include small {
                width: 33px;
            }
        }

        &:hover:before {
            width: 100%;
        }

        //span
        span {
            position: relative;
            display: block;
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            text-align: center;
            white-space: nowrap;
            padding: 12px 52px 12px 24px;
            z-index: 10;

            @include big {
                font-size: 14px;
                padding: 12px 46px 9px 22px;
                letter-spacing: .6px;
            }

            @include small {
                padding: 11px 46px 8px 23px;
                font-size: 13px;
            }
        }
    }

    &.disabled {
        color: $tmp-2;
        background-color: $tmp-1;
    }

    &.default-reverse {
        color: $white-color;
        border: 1px solid $white-color;
        box-shadow: inset 0 0 1px $white-color;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50px;
            width: 38px;
            height: 100%;
            background: transparent;
            transition: all 400ms ease;

            @include small {
                width: 33px;
            }
        }

        &:hover:before {
            background: rgba(250,250,250,.2);
            width: 100%;
        }

        //span
        span {
            position: relative;
            display: block;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: .6px;
            text-align: center;
            white-space: nowrap;
            padding: 12px 23px 8px 23px;
            z-index: 10;

            @include small {
                padding: 10px 24px 7px 24px;
                font-size: 13px;
            }
        }

    }

    &.default-reverse-red {
        color: $happy-red;
        border: 2px solid $happy-red;
        box-shadow: inset 0 0 1px $happy-red;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50px;
            width: 38px;
            height: 100%;
            background: transparent;
            transition: all 400ms ease;

            @include small {
                width: 33px;
            }
        }

        &:hover {
            color: $white-color;
            box-shadow: none;
        }

        &:hover:before {
            background: $happy-red;
            width: 100%;
        }

        //span
        span {
            position: relative;
            display: block;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: .6px;
            text-align: center;
            white-space: nowrap;
            padding: 11px 24px 8px 24px;
            z-index: 10;

            @include small {
                padding: 10px 23px 7px 23px;
                font-size: 13px;
            }
        }

    }

}
