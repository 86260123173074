.u-link {
    position: relative;
    display: inline-block;
    border-radius: 19px;
    text-decoration: none;
    transition: all 200ms ease-in-out;

    //link
    &.demo {
        color: $happy-red;
        background-color: transparent;

        //span
        span {
            font-weight: 600;
            position: relative;
            display: block;
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
            letter-spacing: 0;
            text-align: center;
            white-space: nowrap;
            padding: 10px 52px 10px 24px;
            z-index: 10;
            transition: all 450ms ease;

            @include big {
                letter-spacing: 0;
            }

            @include small {
                font-size: 14px;
                padding: 10px 46px 8px 20px;
                letter-spacing: 0;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 50px;
                width: 36px;
                height: 100%;
                background: $happy-red-hover;
                transition: all 450ms ease;

                @include small {
                    width: 33px;
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50px;
            width: 36px;
            height: 100%;
            background: $happy-red;
            transition: all 450ms ease;
            z-index: 20;

            @include small {
                width: 33px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);
            width: 8px;
            height: 11px;
            background-image: url('../img/arrow-right-solid.svg');
            background-repeat: no-repeat;
            transition: all 450ms ease;
            z-index: 30;

            @include small {
                right: 12px;
            }
        }

        &:hover {
            color: $white-color;
        }

        &:hover:before {
            width: 98%;
            z-index: 0;
        }

    }


    &.small {
        color: $happy-red;
        background-color: transparent;
        margin-left: -19px;

        //span
        span {
            position: relative;
            display: block;
            font-size: 15px;
            line-height: 19px;
            font-weight: 600;
            text-align: center;
            white-space: nowrap;
            padding-top: 2px;
            padding-right: 30px;
            padding-left: 19px;
            height: 20px;
            z-index: 10;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 50px;
                width: 22px;
                height: 22px;
                background: $happy-red-hover;
                transition: all 450ms ease;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50px;
            width: 22px;
            height: 22px;
            background: $happy-red;
            transition: all 450ms ease;
            z-index: 20;
        }

        &:after {
            content: '';
            position: absolute;
            top: 35%;
            right: 7px;
            width: 7px;
            height: 8px;
            background-image: url('../img/arrow-right-small.svg');
            background-repeat: no-repeat;
            transition: all 450ms ease;
            z-index: 30;
        }

        &:hover {
            color: $white-color;
        }

        &:hover:before {
            width: 95%;
            z-index: 0;
        }

    }

    &.default {
        color: $happy-red;
        background-color: transparent;

        //span
        span {
            position: relative;
            display: block;
            font-size: 16px;
            line-height: 1;
            font-weight: 600;
            text-align: center;
            white-space: nowrap;
            z-index: 10;
            padding-right: 37px;
            padding-left: 19px;

            @include big {
                font-size: 15px;
            }

            &:before {
                content: '';
                position: absolute;
                top: -5px;
                right: 0;
                border-radius: 50px;
                width: 24px;
                height: 24px;
                background: $happy-red-hover;
                transition: all 450ms ease;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: -5px;
            right: 0;
            border-radius: 50px;
            width: 24px;
            height: 24px;
            background: $happy-red;
            transition: all 450ms ease;
            z-index: 20;
        }

        &:after {
            content: '';
            position: absolute;
            top: 3px;
            right: 8px;
            width: 7px;
            height: 8px;
            background-image: url('../img/arrow-right-small.svg');
            background-repeat: no-repeat;
            transition: all 450ms ease;
            z-index: 30;
        }

        &:hover {
            color: $white-color;
        }

        &:hover:before {
            width: 93%;
            z-index: 0;
        }
    }
}
