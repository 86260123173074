/* breakpoints */
@mixin large{
    @media (max-width: 1440px) { @content; }
}
@mixin big {
    @media (max-width: 1279px) { @content; }
}
@mixin medium {
    @media (max-width: 960px) { @content; }
}
@mixin small {
    @media (max-width: 760px) { @content; }
}
@mixin xsmall {
    @media (max-width: 480px) { @content; }
}

/* colors */
$tp-color: #383839;
$black-color: #000;
$white-color: #fff;
$grass-green: #2DC659;
$ink-spot: #2C3551;
$happy-red: #FD6234;
$disable-color: #CACACA;

$grass-green-90: #41CB69;
$grass-green-75: #61D483;
$grass-green-50: #95E2AC;
$grass-green-25: #CAF0D5;
$grass-green-8: #EFFBF2;

$black-5: #F2F2F2;
$ink-spot-25: #CACCD3;
$ink-spot-8: #F9F9FC;

$happy-red-hover: #FD815D;

$tmp-1: #DEDEDE;
$tmp-2: #9F9F9F;
$tmp-3: #383839;
$tmp-4: #F1F2F7;
$tmp-6: #E0DFDF;
$tmp-7: #848486;
$tmp-8: #DCF8E4;

/* fonts */
$real-headline: "ff-real-headline-pro";
$real-text: "ff-real-text-pro";

/* padding */
$section-padding: 136px;
$section-padding-mobile: 65px;

.headline {
    position: relative;
    z-index: 10;
}
