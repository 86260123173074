.m-newsletter {
    background-color: $grass-green-8;
    color: $black-color;
    padding-top: $section-padding-mobile;
    padding-bottom: $section-padding-mobile;

    // container
    .container {
        display: flex;

        @include big {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }

    // col
    .col {
        width: 50%;
        align-self: center;

        @include big {
            width: 100%;
        }

        &:last-child {
            margin-left: 103px;
            max-width: 510px;

            @include big {
                margin-top: 30px;
                margin-left: 0;
                max-width: none;
            }
        }
    }

    // headline
    h3 {
        margin-bottom: 10px;
        color: $black-color;
    }

    // text
    .tlb {
        color: $black-color;
    }

    /* form */
    .hbspt-form {
        display: block;
        padding: 0;
        background-color: transparent;
        box-shadow: none;

        form {
            display: flex;
            align-items: flex-end;
            margin: 0 auto;
            max-width: 510px;

            @include small {
                flex-direction: column;
                align-items: stretch;
            }
        }

        .hs-form-field {
            margin: 0;
        }

        .hs_email {
            flex: 1;
            margin-right: 20px;
            text-align: left;

            @include small {
                margin-right: 0;
            }

            input {
                height: 37px;
                background-color: $white-color;
            }
        }

        .hs_submit.hs-submit {
            @include small {
                margin-top: 20px;
            }
        }

        .hs-error-msg {
            bottom: 42px;
        }

        .hs_error_rollup {
            display: none !important;
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: 10px;
        }
    }
}
