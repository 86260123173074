// headings
.huge-text {
	position: relative;
	font-family: $real-headline, ui-sans-serif, sans-serif;
	font-size: 75px;
	font-weight: 900;
	//text-wrap: pretty; //2024-05-13 Remove orphans (only works in Chrome)
	// https://caniuse.com/mdn-css_properties_text-wrap_balance
	//2024-06-20  (should work in all modern browsers)
	text-wrap: balance;
	line-height: 75px;
	display: block;
	z-index: 10;
	color: $black-color;

	@include big {
		font-size: 52px;
		line-height: 55px;
	}

	@include small {
		font-size: 45px;
		line-height: 46px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	position: relative;
	font-family: $real-headline, ui-sans-serif, sans-serif;
	line-height: 1.2;
	text-wrap: pretty; //2024-05-13 Remove orphans (only works in Chrome)
	z-index: 10;
	color: $black-color;
}

h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
}

h1 {
	font-size: 62px;
	line-height: 74px;
	font-weight: 900;

	@include big {
		font-size: 45px;
		line-height: 52px;
	}
}

h2 {
	font-size: 53px;
	line-height: 63px;

	@include big {
		font-size: 30px;
		line-height: 32px;
	}
}

h3,
.like-h3 {
	font-family: $real-headline, ui-sans-serif, sans-serif;
	font-weight: 700;
	font-size: 38px;
	line-height: 45px;

	@include big {
		font-size: 24px;
		line-height: 32px;
	}
}

h4 {
	font-size: 32px;
	line-height: 38px;

	@include big {
		font-size: 24px;
		line-height: 32px;
	}
}

h5 {
	font-size: 24px;
}

h6 {
	font-size: 20px;
	line-height: 24px;
}

.like-h4 {
	font-family: $real-headline, ui-sans-serif, sans-serif;
	font-weight: 700;
	font-size: 32px;
	line-height: 42px;
	color: $black-color;

	@include big {
		font-size: 24px;
		line-height: 32px;
	}
}

// paragraph
p {
	color: $tp-color;
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
}

// subhead paragraph
.tsp {
	font-size: 23px;
	line-height: 34px;
	font-weight: 300;
	color: $tp-color;

	@include big {
		font-size: 16px;
		line-height: 24px;
	}
}

// large body paragraph
.tlb {
	font-size: 18px;
	line-height: 27px;
	font-weight: 300;
	color: $tp-color;

	@include big {
		font-size: 16px;
		line-height: 24px;
	}
}

.bp {
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	color: $tp-color;
}

// overline large
.overline-1 {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.2;
	letter-spacing: 2px;
	text-transform: uppercase;
}

// overline small
.overline-2 {
	font-size: 15px;
	font-weight: 300;
	line-height: 1.2;
	letter-spacing: 2px;
	text-transform: uppercase;

	@include big {
		font-size: 14px;
		line-height: 24px;
	}
}

// text positioning
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}
