.m-featured-resources {
	// container
	.container {
		padding-top: 136px;
		padding-bottom: 136px;

		@include big {
			padding-top: 65px;
			padding-bottom: 65px;
		}
	}

	// headline
	h3 {
		margin-bottom: 45px;
		z-index: 20;
	}

	// wrapper
	.wrapper {
		display: flex;
		justify-content: space-between;

		@include big {
			justify-content: center;
		}

		@include small {
			display: block;
		}

		//col
		.col {
			&:first-child {
				@include big {
					margin: 0 auto;
				}
			}

			&:last-child {
				width: 547px;
				margin-top: 12px;

				@include big {
					margin-top: 0;
					padding-left: 36px;
				}

				@include small {
					padding-left: 0;
					width: 100%;
				}

				@include small {
					margin: 0 auto;
					margin-top: 54px;
				}
			}
		}

		//shape
		.shape {
			position: absolute;
			top: -155px;
			left: -45px;
			z-index: 10;

			@include medium {
				top: -85px;
				left: -15px;

				svg {
					zoom: 0.7;
				}
			}
		}

		#theMotionDot {
			position: absolute;
			top: 0;
			left: 0;
		}

		//img
		img.full {
			display: block;
			width: 620px;
			margin: 0;
			padding: 0;

			@include big {
				width: 100%;
			}

			@include small {
				margin: 0 auto;
				width: 100%;
				max-width: 340px;
			}
		}

		// overline
		.overline-2 {
			margin-bottom: 20px;

			@include big {
				margin-bottom: 6px;
			}
		}

		// headline
		h4 {
			margin-bottom: 30px;

			@include big {
				margin-bottom: 15px;
			}
		}

		//text
		.lb {
			max-width: 520px;
		}

		// button
		.u-button {
			margin-top: 63px;

			@include big {
				margin-top: 33px;
			}

			@include small {
				margin-top: 42px;
			}
		}
	}
}

// featured-resources-list
.m-featured-resources-list {
	// headline
	.headline {
		max-width: 1060px;
		margin: 0 auto;
		margin-bottom: 65px;

		@include medium {
			margin-bottom: 35px;
		}
	}

	// text
	.tsp {
		color: $black-color;
		margin-top: 20px;

		strong {
			font-size: 20px;
			font-weight: 300;
			letter-spacing: 2px;
			text-transform: uppercase;
		}
	}

	// buttons
	.buttons {
		text-align: center;
		margin-top: 65px;

		@include medium {
			margin-top: 35px;
		}
	}

	// container
	.container {
		padding-top: 136px;
		padding-bottom: 136px;

		@include big {
			padding-top: 65px;
			padding-bottom: 65px;
		}
	}
}

// resources-list
.m-resources-list {
	// headline
	.headline {
		max-width: 1060px;
		margin: 0 auto;
		margin-bottom: 65px;

		@include medium {
			margin-bottom: 35px;
		}
	}

	// text
	.tsp {
		color: $black-color;
		margin-top: 20px;

		strong {
			font-size: 20px;
			font-weight: 300;
			letter-spacing: 2px;
			text-transform: uppercase;
		}
	}

	// container
	.container {
		padding-top: 136px;
		padding-bottom: 136px;

		@include big {
			padding-top: 65px;
			padding-bottom: 65px;
		}
	}

	// pagination
	.pagination {
		display: block;
		margin: 0 auto;
		margin-top: 65px;
		text-align: center;

		&--hidden {
			display: none;
		}

		#js-customers-update {
			display: none;
		}

		@include big {
			margin-top: 40px;
		}

		// wrapper
		.pagination-wrapper {
			display: inline-block;
			position: relative;

			// list
			.page-number {
				li {
					position: relative;
					display: inline-block;
					margin: 0 10px;
					padding: 0;
					color: $happy-red;

					& > button,
					& > span {
						position: relative;
						display: block;
						border-radius: 50%;
						color: $happy-red;
						font-size: 15px;
						line-height: 1;
						transition: all 200ms ease-in-out;
						z-index: 20;

						&.is-active {
							color: $white-color;
							cursor: default;

							&:after {
								content: "";
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								background: $happy-red;
								width: 28px;
								height: 28px;
								border-radius: 50%;
								transition: all 200ms ease-in-out;
								z-index: -10;
							}
						}
					}

					&:hover a {
						color: $white-color;

						&:after {
							content: "";
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							background: $happy-red;
							width: 28px;
							height: 28px;
							border-radius: 50%;
							transition: all 200ms ease-in-out;
							z-index: -10;
						}
					}
				}
			}

			// page-navigation
			.page-navigation {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				height: 16px;
				width: 10px;

				&.prev {
					background-image: url("../img/prev-arrow.svg");
					left: -20px;
				}

				&.next {
					background-image: url("../img/next-arrow.svg");
					right: -20px;
				}
			}
		}
	}

	// filtering
	.filtering {
		display: block;
		margin: 0 auto;
		margin-bottom: 55px;
		text-align: center;

		@include small {
			margin-bottom: 0;
		}

		.filtering-list {
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			justify-content: center;
			position: relative;
			z-index: 11;

			@include small {
				flex-direction: column;
			}
		}

		.multi-select-container {
			margin: 0 10px 10px;

			@include small {
				width: 100%;
				margin: 0 0 10px;
			}
		}

		.multi-select-button {
			position: relative;
			display: block;
			max-width: none;
			width: 270px;
			height: 38px;
			line-height: 34px;
			border-radius: 3px;
			border: 2px solid $happy-red;
			color: $tp-color;
			font-size: 16px;
			padding: 0 40px 0 20px;
			transition: all 200ms ease-in-out;
			text-align: left;

			&.reset {
				padding: 0 20px;
				width: auto;
				cursor: pointer;

				@include small {
					width: 100%;
				}
			}

			@include small {
				width: 100%;
			}

			&:after {
				content: "";
				content: none;
			}

			svg {
				position: absolute;
				top: calc(50% - 4px);
				right: 16px;
				transition: all 200ms ease-in-out;

				path {
					transition: all 200ms ease-in-out;
				}
			}

			&:hover {
				color: $happy-red;

				svg {
					-moz-transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					-o-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);

					path {
						stroke: $happy-red;
					}
				}
			}
		}

		.multi-select-menu {
			top: 38px;
			margin: 5px 0 0;
			border: 2px solid $happy-red;
			width: 100% !important;
			max-height: none !important;
			overflow: hidden !important;
		}

		.multi-select-menuitems {
			text-align: left;
			padding: 20px 0;
		}

		.multi-select-menuitem {
			display: flex;
			align-items: center;
			padding: 10px 20px 10px 38px;
			font-size: 16px;

			input[type="checkbox"] {
				margin: 0 12px 0 -20px;
				position: relative;
				top: 0;
				padding: 0;
				width: 16px;
				height: 16px;
				appearance: none;
				outline: none;
				box-shadow: none !important;
				background-color: transparent !important;
				cursor: pointer;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: #fff;
					border: 2px solid #000;
					box-sizing: border-box;
					border-radius: 9999px;
				}

				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 16px;
					height: 16px;
					background-color: transparent;
					box-sizing: border-box;
					display: none;
				}
			}

			input:checked[type="checkbox"]:before {
				background-color: #fd6234;
				// This image does not exist.
				// background-image: url('../img/icon_checkbox-tick.png');
				background-position: center;
				background-repeat: no-repeat;
				width: 16px;
				height: 16px;
				top: 0;
				left: 0;
				border: 2px solid #fec3b2;
				-webkit-box-shadow: inset 0 0 0 1px #fff;
				box-shadow: inset 0 0 0 1px #fff;
			}

			input[type="checkbox"]:focus,
			input[type="checkbox"]:before:focus,
			input[type="checkbox"]:after:focus {
				outline: 0 !important;
				box-shadow: none !important;
			}

			input:checked[type="checkbox"]:after {
				display: block;
			}
		}

		.multi-select-container--open {
			.multi-select-button {
				color: $happy-red;

				svg {
					-moz-transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					-o-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);

					path {
						stroke: $happy-red;
					}
				}
			}
		}
	}

	//buttons
	.buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
}

//preload dropdown icon
body::after {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	z-index: -1;
	content: url("../img/arrow-dropdown-red.svg");
}
