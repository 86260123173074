.m-feature-boxes {

    // container
    .container {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include big {
            padding-top: $section-padding-mobile;
            padding-bottom: $section-padding-mobile;
        }
    }

    // wrapper
    .wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -20px;
        margin-top: 20px;

        // col
        .col {
            width: 50%;
            padding: 0 20px;
            margin-top: 40px;

            @include big {
                width: 100%;
            }
        }

        // box
        .box {
            border: 3px solid $grass-green;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            // image wrapper
            .bg {
                height: 100%;

                img {
                    display: block;
                    margin: 0;
                    padding: 0;
                    width: 195px;
                    height: 100%;
                    object-fit: cover;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                @include small {
                    display: none;
                }
            }

            // content
            .content {
                order: -1;
                padding: 35px 0 45px 35px;

                @include big {
                    padding: 25px;
                }

                @include small {
                    text-align: center;
                }

                // headline
                h5 {
                    @include big {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }

                // text
                .bp {

                    strong {
                        font-weight: 400;
                        background-image: linear-gradient(to right, $grass-green-25 0, $grass-green-25 100%);
                        background-size: 0 90%;
                        background-repeat: no-repeat;
                        transition: background 450ms ease;
                    }
                }

            }

            // headline
            h5 {
                margin-bottom: 15px;
            }

            // button
            .u-button {
                margin-top: 20px;
            }

            &:hover .content .bp strong {
                background-size: 100% 90%;
            }

        }

    }

}
